import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { InputMask } from "@react-input/mask";

import "./style.css";
import { Link } from "react-router-dom";

const OrganisationTable = ({ tableRow, tableData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [organisationName, setOrganisationName] = useState("");
  const [organisationMail, setOrganisationMail] = useState("");
  const [organisationPhone, setOrganisationPhone] = useState("");
  const [organisationAuthorizedPerson, setOrganisationAuthorizedPerson] =
    useState("");
  const [organisationFloors, setOrganisationFloors] = useState("");
  const [organisationRooms, setOrganisationRooms] = useState("");
  const [organisationBilling, setOrganisationBilling] = useState("");
  const [
    organisationAuthorizedPersonActive,
    setOrganisationAuthorizedPersonActive,
  ] = useState("");
  const [organisationActive, setOrganisationActive] = useState("");

  async function addOrganisation() {
    try {
      const response = await fetch(
        "https://app.vivasmartcare.com/api/add-organisation",
        {
          method: "POST",
          body: JSON.stringify({
            organisationName,
            organisationMail,
            organisationPhone,
            organisationAuthorizedPerson,
            organisationFloors,
            organisationRooms,
            organisationBilling,
            organisationAuthorizedPersonActive,
            organisationActive,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.ok) {
        alert("Organizasyon başarıyla eklendi");
        setOrganisationName("");
        setOrganisationMail("");
        setOrganisationPhone("");
        setOrganisationAuthorizedPerson("");
        setOrganisationFloors("");
        setOrganisationRooms("");
        setOrganisationBilling("");
        setOrganisationAuthorizedPersonActive("");
        setOrganisationActive("");
        window.location.reload();
      } else {
        alert("Organizasyon eklenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Ekleme işlemi sırasında bir hata oluştu:", error);
    }
  }

  return (
    <div className="user-table">
      <div className="users-filter-inputs">
        <Form.Group>
          <Form.Control type="text" placeholder="Kurum Adına Göre Ara" />
        </Form.Group>
        <Form.Group>
          <Button variant="primary" onClick={handleShow}>
            Kurum Ekle
          </Button>
        </Form.Group>
      </div>
      <Table striped bordered hover responsive className="alarm-table">
        <thead>
          <tr>
            {tableRow.map((tableRow, index) => (
              <th key={index}>{tableRow}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td>{data.organisationName}</td>
              <td>{data.organisationActive}</td>
              <td>{data.organisationMail}</td>
              <td>
                <Link to={`/organisation/${data._id}`}>Düzenle</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Kurum Ekle</Modal.Header>
        <Modal.Body className="organisation-modal-inputs">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Kurum İsmi Giriniz:"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Kurum Yetkilisi İsim:"
              value={organisationAuthorizedPerson}
              onChange={(e) => setOrganisationAuthorizedPerson(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Kurum Yetkilisi Mail:"
              value={organisationMail}
              onChange={(e) => setOrganisationMail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <InputMask
              mask="+90__________"
              replacement={{ _: /\d/ }}
              value={organisationPhone}
              className="form-control"
              placeholder="Kurum Yetkilisi Telefon"
              showMask
              onChange={(e) => setOrganisationPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Kat Sayısı:"
              value={organisationFloors}
              onChange={(e) => setOrganisationFloors(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Oda Sayısı:"
              value={organisationRooms}
              onChange={(e) => setOrganisationRooms(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Fatura Bilgisi:"
              value={organisationBilling}
              onChange={(e) => setOrganisationBilling(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              value={organisationActive}
              onChange={(e) => setOrganisationActive(e.target.value)}
            >
              <option>Kurum Aktiflik</option>
              <option value="Aktif">Aktif</option>
              <option value="Pasif">Pasif</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Select
              value={organisationAuthorizedPersonActive}
              onChange={(e) =>
                setOrganisationAuthorizedPersonActive(e.target.value)
              }
            >
              <option>Kurum Yetkilisi Aktiflik</option>
              <option value="Aktif">Aktif</option>
              <option value="Pasif">Pasif</option>
            </Form.Select>
          </Form.Group>
          <div className="organisation-buttons">
            <Button variant="secondary" onClick={handleClose}>
              İptal
            </Button>
            <Button variant="primary" onClick={addOrganisation}>
              Kurum Ekle
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrganisationTable;
