import { useEffect, useState, useContext } from "react";

import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";

import "./style.css";
import { DasboardContext } from "../../context/dashboard-context";

const AlarmModal = ({ alarm, userDetail, bed, closeModal }) => {
  const [detail, setDetail] = useState();
  const [id, setId] = useState();
  const [callCount, setCallCount] = useState();
  const [confirmation, setConfirmation] = useState();
  const [description, setDescription] = useState();
  const [resolved, setResolved] = useState("true");
  const [alarmScenario, setAlarmScenario] = useState();

  const { userInfo } = useContext(DasboardContext);

  const person = userInfo?.username;

  useEffect(() => {
    const foundDetail = userDetail.find((item) => item.userName === bed.person);
    if (foundDetail) {
      setDetail(foundDetail);
      setId(alarm._id);
    }
  }, []);

  function convertDate(timestampString) {
    const timestamp = Number(timestampString);

    if (isNaN(timestamp)) {
      return "Geçersiz zaman damgası";
    }

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return "Geçersiz zaman damgası";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    const formattedDate = date.toLocaleString("tr-TR", options);

    return formattedDate;
  }

  async function updateAlarmLog() {
    try {
      const response = await fetch("https://app.vivasmartcare.com/api/alarm-update", {
        method: "PUT",
        body: JSON.stringify({
          id,
          resolved,
          callCount,
          confirmation,
          description,
          alarmScenario,
          person,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        alert("Alarm Durumu Güncellendi");
        window.location.reload();
      } else {
        alert("Alarm durumu güncellenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Güncelleme işlemi sırasında bir hata oluştu:", error);
    }
  }

  return (
    <div className="alarm-modal">
      <Tabs defaultActiveKey="alarm" className="mb-3">
        <Tab eventKey="alarm" title="Alarm Bilgileri">
          <div className="infos-wrapper">
            <h5>
              {detail && detail.length > 0
                ? detail[0].nameSurname
                : "Bilgi yok"}
            </h5>
            <div className="ring-info">
              <div className="info-box">
                {alarm.eventType === "calling" && "Düşme Alarmı"}
              </div>
              <div className="info-box">
                Cihaz:
                {detail && detail.length > 0
                  ? detail[0].devicesUsed
                  : "Bilgi yok"}
              </div>
              <div className="info-box">
                Tarih: {convertDate(alarm.endTimestamp)}
              </div>
              <div className="info-box">
                İletişim:
                {detail && detail.length > 0
                  ? detail[0].emergencyContacts
                  : "Bilgi yok"}
              </div>
              <div className="info-box">
                <Form.Select
                  value={callCount}
                  onChange={(e) => setCallCount(e.target.value)}
                >
                  <option>Aranma Sayısı</option>
                  <option value="1 defa">1 Defa</option>
                  <option value="2 defa">2 Defa</option>
                  <option value="3 defa">3 Defa</option>
                </Form.Select>
              </div>
              <div className="info-box">
                <Form.Select
                  value={confirmation}
                  onChange={(e) => setConfirmation(e.target.value)}
                >
                  <option>Doğruluk</option>
                  <option value="Teyit Edildi">Teyit Edildi</option>
                  <option value="Teyit Edilmedi">Teyit Edilmedi</option>
                </Form.Select>
              </div>
              <div>
                <Form.Select
                  value={alarmScenario}
                  onChange={(e) => setAlarmScenario(e.target.value)}
                >
                  <option>Durum Senaryosu</option>
                  <option
                    value="Acil durum kontak listesinden birine ulaşıldı ve yaşlı ile
                      ilgileniyor"
                  >
                    Acil durum kontak listesinden birine ulaşıldı ve yaşlı ile
                    ilgileniyor
                  </option>
                  <option
                    value="Acil Durum Kontak Listesine ulaşılamadı, yardım için
                      ambulans gönderildi. Yakınına bilgilendirme gönderildi"
                  >
                    Acil Durum Kontak Listesine ulaşılamadı, yardım için
                    ambulans gönderildi. Yakınına bilgilendirme gönderildi
                  </option>
                  <option value="Yaşlıya ulaşıldı, yanlış alarm, yaşlı iyi her şey yolunda">
                    Yaşlıya ulaşıldı, yanlış alarm, yaşlı iyi her şey yolunda
                  </option>
                  <option value="Yaşlıya ulaşıldı, doğru alarm, yaşlı şuan iyi şikayeti yok">
                    Yaşlıya ulaşıldı, doğru alarm, yaşlı şuan iyi şikayeti yok
                  </option>
                </Form.Select>
              </div>
              <Form.Control
                as="textarea"
                placeholder="Ekstra notunuz varsa belirtin."
                onResize={() => false}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="button-wrapper">
            <Button variant="secondary" onClick={closeModal}>
              İptal
            </Button>
            <Button variant="primary" onClick={updateAlarmLog}>
              Kaydet
            </Button>
          </div>
        </Tab>
        <Tab eventKey="patient" title="Hasta Bilgileri">
          <div className="infos-wrapper tab-2">
            <h5>
              {detail && detail.length > 0
                ? detail[0].nameSurname
                : "Bilgi yok"}
            </h5>
            <div className="ring-info">
              <div className="info-box">
                <strong>Adres:</strong>{" "}
                {detail && detail.length > 0 ? detail[0].address : "Bilgi yok"}
              </div>
              <div className="info-box">
                <p>Konum:</p>
                <iframe
                  src={
                    detail && detail.length > 0
                      ? detail[0].addressLink
                      : "Bilgi yok"
                  }
                  width="100%"
                  height="250"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="info-box">
                <strong>Anamnez:</strong>
                {detail && detail.length > 0
                  ? detail[0].anamnesis
                  : "Bilgi yok"}
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AlarmModal;
