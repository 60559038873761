import { useContext } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DeviceTable from "../../components/DeviceTable/DeviceTable";

import "./style.css";

import useGetData from "../../hooks/useGetData";

const Devices = () => {
  const tableTD = [
    "",
    "Cihaz ID",
    "Kurum",
    "Kişi",
    "Durum",
    "Marka",
    "Oda İsmi",
    "Süit İsmi",
  ];

  const [tableData] = useGetData("devices");

  const { sidebarOpen, bedData } = useContext(DasboardContext);

  return (
    <>
      <Breadcrumb />
      <div
        className={`device-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <DeviceTable tableTd={tableTD} tableData={bedData} />
      </div>
    </>
  );
};

export default Devices;
