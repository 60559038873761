import {
  BsFillHeartPulseFill,
  BsLungsFill,
  BsFillMoonFill,
} from "react-icons/bs";
import { SiOxygen } from "react-icons/si";
import { LiaTemperatureHighSolid } from "react-icons/lia";
import { IoFootstepsSharp } from "react-icons/io5";

import "./style.css";

const PersonelBanner = () => {
  return (
    <div className="personel-banner">
      <div className="name">
        <p>Veysel Kartalmış</p>
        <span>Corsano Trials</span>
      </div>
      <div className="statistics">
        <div className="statistic">
          <BsFillHeartPulseFill />
          <div>
            <p className="big-t">75</p>
            <p className="small-t">BPM</p>
          </div>
        </div>
        <div className="statistic">
          <SiOxygen />
          <div>
            <p className="big-t yellow">93</p>
            <p className="small-t yellow">Saturation</p>
          </div>
        </div>
        <div className="statistic">
          <BsLungsFill />
          <div>
            <p className="big-t">16.0</p>
            <p className="small-t">BRPM</p>
          </div>
        </div>
        <div className="statistic">
          <IoFootstepsSharp />
          <div>
            <p className="big-t">145</p>
            <p className="small-t">Steps</p>
          </div>
        </div>
        <div className="statistic">
          <BsFillMoonFill />
          <div>
            <p className="big-t">07:37</p>
            <p className="small-t">Sleep</p>
          </div>
        </div>
        <div className="statistic">
          <LiaTemperatureHighSolid />
          <div>
            <p className="big-t">37.1</p>
            <p className="small-t">Temperature</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelBanner;
