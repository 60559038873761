import { useContext } from "react";
import { DasboardContext } from "../../context/dashboard-context";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ReportsTable from "../../components/ReportsTable/ReportsTable";
import PersonelBanner from "../../components/PersonelBanner/PersonelBanner";

import { faker } from "@faker-js/faker";
import ReportsDetailTable from "../../components/ReportsDetailTable/ReportsDetailTable";

const Reports = () => {
  const { sidebarOpen } = useContext(DasboardContext);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Vital Alarmlar",
      },
    },
  };

  const labels = [
    "1. Hafta",
    "2. Hafta",
    "3. Hafta",
    "4. Hafta",
    "5. Hafta",
    "6. Hafta",
    "7. Hafta",
    "8. Hafta",
    "9. Hafta",
    "10. Hafta",
  ];

  const tableTD = ["Saat Dilimi", "Süit Adı", "Olay Türü"];

  const tableData = [
    {
      timeZone: "05:51:17 (UTC+03:00) Istanbul March 28, 2023",
      suitName: "İdealtepe 306",
      eventType: "Düşme Algılandı",
    },
    {
      timeZone: "05:51:17 (UTC+03:00) Istanbul March 28, 2023",
      suitName: "İdealtepe 307",
      eventType: "Düşme Algılandı",
    },
    {
      timeZone: "05:51:17 (UTC+03:00) Istanbul March 28, 2023",
      suitName: "İdealtepe 308",
      eventType: "Düşme Algılandı",
    },
    {
      timeZone: "05:51:17 (UTC+03:00) Istanbul March 28, 2023",
      suitName: "İdealtepe 310",
      eventType: "Düşme Algılandı",
    },
    {
      timeZone: "05:51:17 (UTC+03:00) Istanbul March 28, 2023",
      suitName: "İdealtepe 311",
      eventType: "Düşme Algılandı",
    },
    {
      timeZone: "05:51:17 (UTC+03:00) Istanbul March 28, 2023",
      suitName: "İdealtepe 312",
      eventType: "Düşme Algılandı",
    },
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Overview",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Pulse Rate",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "HRV",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(13, 12, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "SPO2",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(48, 21, 122)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Respiration",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(50, 12, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Activity",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(10, 200, 212)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Temperature",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(100, 41, 31)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "NIBP",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(69, 44, 144)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "BIOZ",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(69, 44, 144)",
        backgroundColor: "rgba(99, 11, 23, 0.5)",
      },
      {
        label: "Surveys",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
        borderColor: "rgb(74, 24, 244)",
        backgroundColor: "rgba(99, 11, 23, 0.5)",
      },
    ],
  };

  return (
    <>
      <Breadcrumb />
      <div
        className={`user-list-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <PersonelBanner />
        <ReportsTable options={options} data={data} />
        <ReportsDetailTable tableData={tableData} tableTD={tableTD} />
      </div>
    </>
  );
};

export default Reports;
