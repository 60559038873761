import { useContext, useState, useEffect } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import logo from "../../assets/images/logo.png";
import "./style.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const { setUserInfo } = useContext(DasboardContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function login(e) {
    e.preventDefault();

    const response = await fetch("https://app.vivasmartcare.com/api/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.ok) {
      alert("Giriş Başarılı");
      response.json().then((userInfo) => {
        setRedirect(true);
        setUserInfo(userInfo);
        navigate("/homepage");
      });
    } else {
      alert("Kullanıcı adı veya parola hatalı");
      setUsername("");
      setPassword("");
    }
  }

  return (
    <div className="login-wrapper">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <h3>Viva Smart Care Yönetim Paneli</h3>

      <Form className="form-wrapper" onSubmit={login}>
        <Form.Group>
          <p>Kullanıcı Adı:</p>
          <Form.Control
            type="text"
            placeholder="Kullanıcı Adı"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <p>Şifrenizi Giriniz:</p>
          <div style={{ position: "relative" }}>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Şifre"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </Form.Group>
        <Form.Group className="remember-forget">
          <Form.Check type="switch" id="custom-switch" label="Beni Hatırla" />
          <Link to={"/forget-password"}>Şifremi Unuttum</Link>
        </Form.Group>
        <Form.Group className="login-button">
          <Button variant="primary" type="submit">
            Giriş Yap
          </Button>
          <Link to={"/sign-in"}>Üye Ol</Link>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Login;
