import UserDetailScreen from "../../components/UserDetailScreen/UserDetailScreen";
import "./style.css";

const UserDetail = () => {
  return (
    <div className="user-detail-wrapper">
      <UserDetailScreen />
    </div>
  );
};

export default UserDetail;
