import { useContext, useEffect, useState } from "react";

import { DasboardContext } from "../../context/dashboard-context";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import "./style.css";
import OrganisationDetailScreen from "../../components/OrgnisationDetailScreen/OrganisationDetailScreen";

const OrganisationDetail = () => {
  const { sidebarOpen } = useContext(DasboardContext);

  return (
    <>
      <Breadcrumb />
      <div
        className={`alarms-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <OrganisationDetailScreen />
      </div>
    </>
  );
};

export default OrganisationDetail;
