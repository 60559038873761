import { useContext, useState, useEffect } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import OrganisationTable from "../../components/OrganisationTable/OrganisationTable";
import "./style.css";

const Organisation = () => {
  const { sidebarOpen } = useContext(DasboardContext);
  const tableRow = [
    "Huzuevi Adı",
    "Aktiflik Durumu",
    "İletişim Maili",
    "Düzenle",
  ];
  const [tableData, setTableData] = useState([]);

  const getOrganisations = async () => {
    try {
      const response = await fetch(
        `https://app.vivasmartcare.com/api/organisations`
      );
      const organisations = await response.json();
      setTableData(organisations);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    getOrganisations();
  }, []);

  return (
    <>
      <Breadcrumb />
      <div
        className={`organisation-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <OrganisationTable tableData={tableData} tableRow={tableRow} />
      </div>
    </>
  );
};

export default Organisation;
