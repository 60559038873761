import { Table } from "react-bootstrap";

import "./style.css";

const TaskTable = ({ taskList }) => {
  return (
    <div>
      <Table striped bordered hover responsive className="task-table">
        <thead>
          <tr>
            <th>Alarm Tipi</th>
            <th>Kullanıcı Adı</th>
            <th>Alarm Durumu</th>
          </tr>
        </thead>
        <tbody>
          {taskList.map((data, index) => (
            <tr key={index}>
              <td>{data.alarmType}</td>
              <td>{data.userName}</td>
              <td
                className={`${data.taskType === "Aktif" ? "red" : ""} 
                ${data.taskType === "Çözümlendi" ? "green" : ""} ${
                  data.taskType === "Kontrol" ? "orange" : ""
                } `}
              >
                {data.taskType}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TaskTable;
