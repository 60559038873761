import { createContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import mqtt from "mqtt";
import Cookies from "js-cookie";

const DasboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [profileName, setProfileName] = useState();
  const [bedData, setBedData] = useState([]);
  const [states, setStates] = useState([]);
  const [events, setEvents] = useState([]);
  const [topic, setTopic] = useState();
  const [usernames, setUsernames] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [sameSuit, setSameSuit] = useState([]);

  const [userInfo, setUserInfo] = useState({});

  const [checkedItems, setCheckedItems] = useState([]);

  const getUsernames = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/usernames`);
      setUsernames(await response.json());
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  async function sendSMS(targetNumbers, roomName, organization, suiteName) {
    try {
      const requests = targetNumbers.map((targetNumber) =>
        fetch("https://app.vivasmartcare.com/api/messages", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            targetNumber: targetNumber,
            roomName: roomName,
            organization: organization,
            suiteName: suiteName,
          }),
        })
      );

      const responses = await Promise.all(requests);

      responses.forEach((response, index) => {
        if (response.ok) {
          console.log(
            `Mesaj ${targetNumbers[index]} numarasına başarıyla gönderildi`
          );
        } else {
          console.error(
            `HTTP Hatası ${targetNumbers[index]} için:`,
            response.status
          );
        }
      });
    } catch (error) {
      console.error("Bir hata oluştu:", error);
    }
  }

  async function addAlarmLog({
    deviceId,
    endTimestamp,
    eventType,
    resolved,
    callCount,
    confirmation,
    description,
    alarmScenario,
    person,
  }) {
    try {
      const response = await fetch("https://app.vivasmartcare.com/api/log-add", {
        method: "POST",
        body: JSON.stringify({
          deviceId,
          endTimestamp,
          eventType,
          resolved,
          callCount,
          confirmation,
          description,
          alarmScenario,
          person,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        console.log("Alarm başarıyla eklendi");
      } else {
        alert("Alarm eklenirken bir hata oluştu");
      }
    } catch (error) {
      console.error(
        "Alarm log ekleme işlemi sırasında bir hata oluştu:",
        error
      );
    }
  }

  const fetchUserInfo = async () => {
    const token = Cookies.get("token");
    if (token !== undefined) {
      try {
        const response = await fetch(`https://app.vivasmartcare.com/api/profile/${token}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const userInfo = await response.json();
        setUserInfo(userInfo);
        return userInfo;
      } catch (error) {
        console.error("Failed to fetch user info:", error);
        return null;
      }
    }
    return null;
  };

  const getDevices = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/devices`);
      const devices = await response.json();
      const userInfoCompany = await fetchUserInfo();
      let lastDevices;

      if (userInfoCompany?.company !== "all") {
        lastDevices = devices.filter(
          (device) => device.organization === userInfoCompany?.company
        );
      } else {
        lastDevices = devices;
      }

      setBedData(lastDevices);

      const groupedData = await lastDevices?.reduce((acc, item) => {
        const { suiteName } = item;
        if (suiteName) {
          if (!acc[suiteName]) {
            acc[suiteName] = [];
          }
          acc[suiteName].push(item);
        }
        return acc;
      }, {});

      setSameSuit(
        Object.keys(groupedData).map((suiteName) => ({
          suiteName,
          items: groupedData[suiteName],
        }))
      );
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  const addVayyarLog = async (deviceId, vayyarData, dataType) => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/vayyar-log`, {
        method: "POST",
        body: JSON.stringify({
          deviceId,
          vayyarData,
          dataType,
        }),
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      console.error("Vayyar logu eklenirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    getUsernames();

    const brokerUrl =
      "wss://320b1fdf041d4d74ab162229af8aecb9.s1.eu.hivemq.cloud:8884/mqtt";
    const options = {
      username: "hivemq.webclient.1701086780574",
      password: "qB3e@b9ZfCKL.6c;!F2l",
    };

    const client = mqtt.connect(brokerUrl, options);

    client.on("connect", () => {
      const topics = bedData
        .filter((device) => !checkedItems.includes(device.deviceID))
        .flatMap((device) => [
          `/devices/${device.deviceID}/events`,
          `/devices/${device.deviceID}/state`,
        ]);

      topics.map((top) => {
        client.subscribe(top, (err) => {
          if (!err) {
            console.log("MQTT bağlantısı başarılı");
          } else {
            console.error(
              `"${top}" konusuna abone olunurken bir hata oluştu: ${err}`
            );
          }
        });
      });
    });

    client.on("error", (error) => {
      console.error("MQTT bağlantı hatası:", error);
    });

    client.on("message", (topic, message) => {
      var alert = JSON.parse(message.toString("utf-8"));

      setTopic(topic);

      const parts = topic.split("/");
      const lastPart = parts[parts.length - 1];

      const deviceIDToCheck = parts[2];
      let matchFound = false;

      bedData?.map((device) => {
        if (device.deviceID === deviceIDToCheck) {
          matchFound = true;
        }
      });

      if (matchFound) {
        if (lastPart === "state") {
          setStates(alert);
          //addVayyarLog(deviceIDToCheck, alert, "state");
        } else if (lastPart === "events") {
          setEvents(alert);
          //addVayyarLog(deviceIDToCheck, alert, "event");
          if (alert.payload?.status === "calling") {
            var audio = new Audio(
              "https://assets.mixkit.co/active_storage/sfx/861/861-preview.mp3"
            );
            audio.play();
            addAlarmLog({
              deviceId: alert.payload.deviceId,
              endTimestamp: alert.payload.timestamp,
              eventType: alert.payload.status,
              resolved: "false",
              callCount: "",
              confirmation: "",
              description: "",
              alarmScenario: "",
              person: "",
            });

            fetch("https://app.vivasmartcare.com/api/devices")
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.json();
              })
              .then((data) => {
                const targetPhone = data?.find(
                  (item) => item.deviceID === alert.payload.deviceId
                );
                sendSMS(
                  targetPhone.smsPhone,
                  targetPhone.roomName,
                  targetPhone.organization,
                  targetPhone.suiteName
                )
                  .then(console.log("Sms gönderildi " + targetPhone.smsPhone))
                  .then(setModalShow(true));
              })
              .catch((error) => {
                console.error(
                  "There was a problem with the fetch operation:",
                  error
                );
              });
          }
        }
      }
    });
  }, [bedData, checkedItems]);

  return (
    <DasboardContext.Provider
      value={{
        isLogin,
        setIsLogin,
        sidebarOpen,
        setSidebarOpen,
        profileName,
        setProfileName,
        bedData,
        topic,
        states,
        events,
        usernames,
        userInfo,
        setUserInfo,
        sameSuit,
        checkedItems,
        setCheckedItems,
      }}
    >
      <Modal show={modalShow}>
        <Modal.Header>Bir bildirim aldınız!</Modal.Header>
        <Modal.Body className="device-add-wrapper">
          <p>Bir düşme bildirimi aldınız!</p>
          <div className="button-wrapper">
            <Button
              variant="secondary"
              onClick={() => {
                setModalShow(false);
                window.location.reload();
              }}
            >
              Kapat
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {children}
    </DasboardContext.Provider>
  );
};

export { DasboardContext, DashboardProvider };
