import { useContext } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import LoginDetails from "../../components/LoginDetails/LoginDetails";

const LoginDetail = () => {
  const { sidebarOpen } = useContext(DasboardContext);

  return (
    <>
      <Breadcrumb />
      <div
        className={`organisation-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <LoginDetails />
      </div>
    </>
  );
};

export default LoginDetail;
