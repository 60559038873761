import { useState, useEffect, useContext } from "react";
import { DasboardContext } from "../context/dashboard-context";

const useGetData = (parameter) => {
  const [data, setData] = useState([]);
  const { userInfo } = useContext(DasboardContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://app.vivasmartcare.com/api/${parameter}`
        );
        const devices = await response.json();
        setData(
          devices.filter((device) => device.organization === userInfo?.company)
        );
      } catch (error) {
        console.error("Veri getirilirken bir hata oluştu:", error);
      }
    };

    fetchData();
  }, [parameter]);

  return [data];
};

export default useGetData;
