import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { DasboardContext } from "../../context/dashboard-context";

const LoginDetails = () => {
  const [id, setId] = useState();
  const [username, setUsername] = useState("");
  const [mail, setMail] = useState("");
  const [tcNo, setTcNo] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [profileName, setProfileName] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getLoginDetail() {
    try {
      const response = await fetch(
        `https://app.vivasmartcare.com/api/login/${profileName}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsername(data[0].username);
        setMail(data[0].mail);
        setTcNo(data[0].tcNo);
        setBirthday(data[0].birthday);
        setPhoneNumber(data[0].phoneNumber);
        setId(data[0]._id);
      } else {
        console.log("Profil getirilirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Bir hata oluştu:", error);
    }
  }

  async function updateLoginDetail() {
    try {
      const response = await fetch("https://app.vivasmartcare.com/api/login-update", {
        method: "PUT",
        body: JSON.stringify({
          id,
          username,
          mail,
          tcNo,
          birthday,
          phoneNumber,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        alert("Kullanıcı Güncellendi");
        window.location.reload();
      } else {
        alert("Kullanıcı güncellenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Güncelleme işlemi sırasında bir hata oluştu:", error);
    }
  }

  useEffect(() => {
    const fetchProfile = async () => {
      const token = Cookies.get("token");
      if (token) {
        try {
          const response = await fetch(
            `https://app.vivasmartcare.com/api/profile/${token}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );

          if (response.ok) {
            const userInfo = await response.json();
            setProfileName(userInfo.username);
          } else {
            console.error("Profile fetch failed", response.status);
          }
        } catch (error) {
          console.error("An error occurred while fetching profile:", error);
        }
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    if (profileName) {
      getLoginDetail();
    }
  }, [profileName]);

  return (
    <>
      <div>
        <p>Kullanıcı Giriş Bilgileri</p>
        <div className="user-detail-screen">
          <div className="device-info-wrapper">
            <div className="info">
              <span>Kullanıcı Adı:</span>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="info">
              <span>Mail Adresi:</span>
              <Form.Control
                type="text"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </div>
            <div className="info">
              <span>TC No:</span>
              <Form.Control
                type="text"
                value={tcNo}
                onChange={(e) => setTcNo(e.target.value)}
              />
            </div>
            <div className="info">
              <span>Doğum Tarihi:</span>
              <Form.Control
                type="date"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
              />
            </div>
            <div className="info">
              <span>Telefon Numarası:</span>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="button-wrapper">
              <Button variant="primary" onClick={updateLoginDetail}>
                Kaydet - Güncelle
              </Button>
              <Button variant="primary" onClick={handleShow}>
                Sözleşmeyi Görüntüle
              </Button>
            </div>
          </div>
        </div>
        <Modal show={show}>
          <Modal.Header closeButton>
            <Modal.Title>Sözleşme Detayları</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Burada ilgili yerden gelecek olan bir sözleşme metni olacaktır.
            Kullanıcı kabul ettiği sözleşmeyi burada görüntüleyebilecektir.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Okudum anladım
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LoginDetails;
