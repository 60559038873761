import { useEffect } from "react";
import "./App.css";
import MainScreen from "./pages/MainScreen/MainScreen";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  useEffect(() => {
    const hasRefreshed = localStorage.getItem("hasRefreshed");

    if (hasRefreshed !== "true") {
      localStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
  }, []);

  return (
    <>
      <MainScreen />
      <ScrollToTop />
    </>
  );
}

export default App;
