import { useContext } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import TaskTable from "../../components/TaskTable/TaskTable";
import "./style.css";

const TaskList = () => {
  const taskList = [
    {
      alarmType: "Düşme Alarmı",
      userName: "Veysel Kartalmış",
      taskType: "Aktif",
    },
    {
      alarmType: "Teknik Alarmı",
      userName: "Veysel Kartalmış",
      taskType: "Çözümlendi",
    },
    {
      alarmType: "Vital Alarmı",
      userName: "Veysel Kartalmış",
      taskType: "Kontrol",
    },
  ];

  const { sidebarOpen } = useContext(DasboardContext);
  return (
    <>
      <Breadcrumb />
      <div
        className={`task-list-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <TaskTable taskList={taskList} />
      </div>
    </>
  );
};

export default TaskList;
