import logo from "../../assets/images/logo.png";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./style.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import Modal from "react-bootstrap/Modal";

const SignIn = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [tcNo, setTcNo] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [modalShow, setModalShow] = useState("");
  const [errors, setErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signShow, setSignShow] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => setChecked(!checked);

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setTcNo(value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function addUser(e) {
    e.preventDefault();

    if (checked) {
      const phonePattern = /^\+90\d{10}$/;
      if (!phonePattern.test(phoneNumber)) {
        setErrors("Lütfen geçerli bir telefon numarası girin.");
        setModalShow(true);
        return;
      }
      try {
        const response = await fetch("https://app.vivasmartcare.com/api/add-user", {
          method: "POST",
          body: JSON.stringify({
            username,
            password,
            mail,
            tcNo,
            birthday,
            phoneNumber,
          }),
          headers: { "Content-Type": "application/json" },
        });

        if (response.ok) {
          alert("Kullanıcı başarıyla oluşturuldu");
          navigate("/login");
        } else {
          const errorData = await response.json();
          setErrors(await errorData.message);
          setModalShow(true);
        }
      } catch (error) {
        console.error("Ekleme işlemi sırasında bir hata oluştu:", error);
        setErrors("Ekleme işlemi sırasında bir hata oluştu.");
        setModalShow(true);
      }
    } else {
      setErrors("Kullanıcı sözleşmesini kabul etmelisiniz.");
      setModalShow(true);
    }
  }

  return (
    <div className="login-wrapper full-size">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <h3>Viva Smart Care Yönetim Paneli Üyelik Sistemi</h3>
      <div className="form-wrapper grid">
        <Form onSubmit={addUser}>
          <Modal size="lg" show={signShow}>
            <Modal.Header>
              <Modal.Title>Kullanıcı Sözleşmesi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pdf-container">
                <p>
                  <br /> 1. KULLANIM ŞARTLARI <br /> TARAFLAR İşbu Kullanım
                  Şartları, Vişnezade Mah. Babaefendi Sk. No: 5 İç Kapı No: 5
                  Beşiktaş/İstanbul adresinde mukim Vivasmartcare Sağlık Hizmet
                  ve Ürünleri Ticaret Anonim Şirketi tüzel kişi (“Site Sahibi”)
                  https://app.vivasmartcare.com isimli internet sitesi (“Site”)
                  gerçek ve tüzel kişiler ile tüketici olması fark etmeksizin
                  ziyaretçiler (“Ziyaretçi”) arasında Site’nin kullanım
                  koşullarının belirlenmesi ve Site Sahibi ile Ziyaretçi’nin hak
                  ve yükümlülüklerinin belirlenmesi kapsamında Site’de yayımı
                  ile yürürlüğe girmiştir. <br />
                  <br /> 2. AMAÇ VE KAPSAM Kullanım Şartları, Sitenin kullanım
                  şartlarının belirlenmesi amacıyla ve Site’nin Ziyaretçiler
                  tarafından kullanımına ilişkin hak ve yükümlülüklerinin
                  belirlenmesi kapsamında düzenlenmiştir. <br />
                  <br /> 3. ZİYARETÇİNİN HAK VE YÜKÜMLÜLÜKLERİ <br /> 3.1
                  Ziyaretçi, Site’nin tüm internet kullanıcılarına açık olup,
                  Site Sahibi’nden hizmet alınması amacıyla oluşturulmuş resmi
                  site olduğunu kabul ve beyan eder. <br />
                  3.2 Ziyaretçi, işbu Kullanım Şartlarını kabul etmek hususunda
                  herhangi bir yasal engelinin bulunmadığını kabul, beyan ve
                  taahhüt eder. <br />
                  3.3 Site’nin kullanımı için Ziyaretçi tarafından üyelik hesabı
                  oluşturulması gereklidir. <br />
                  3.4 Ziyaretçi, Site’yi hukuka uygun amaçlarla kullanacağını;
                  Site Sahibi ve/veya üçüncü kişilerin hak ihlaline maruz
                  kalacağı faaliyetlerde bulunmayacağını, Site bünyesinde
                  gerçekleştirdiği iş ve işlemlerden doğabilecek hukuki ve cezai
                  sorumluluğun kendisine ait olduğunu ve işbu iş ve eylemler
                  sebebiyle üçüncü kişilerin uğradığı yahut uğrayabileceği
                  zararlar kapsamında Site Sahibi’nin dolaylı veya dolaysız
                  olarak herhangi bir sorumluluğunun bulunmadığını kabul, beyan
                  ve taahhüt eder. <br />
                  3.5 Ziyaretçi; Site Sahibi’nin, işbu Kullanım Koşulları dahil
                  Site’de yer alan her türlü, yazı, görsel, sunu, Site
                  Sahibi’nin sunduğu hizmetlere ilişkin bilgi ve tanıtım metni
                  vb her türlü içeriği, değiştirme, güncelleme, kaldırma, askıya
                  alma hakkını haiz olduğunu kabul, beyan ve taahhüt eder.{" "}
                  <br />
                  3.6 Ziyaretçi, Site’yi kullanması esnasında Site ve/veya Site
                  Sahibi hakkında Türkiye Cumhuriyeti mevzuatına aykırı, eksik,
                  yanlış, yanıltıcı yahut genel ahlaka uygun olmayan bilgileri
                  Site’ye kaydetmeyeceğini kabul, beyan ve taahhüt eder. Yine
                  Ziyaretçi, bu şartların yanında Site’nin güvenliğini tehlikeye
                  atacak, Site’nin çalışmasında kullanılan yazılım ve/veya
                  donanımı engelleyecek yahut bu yazılım ve/veya donanımın
                  bilgilerinin alınmasını, kopyalanmasını, değiştirilmesi yahut
                  silinmesini sebep olacak her türlü iş ve eylemden kaçınacağını
                  ve gerekli önlemleri alacağını kabul, beyan ve taahhüt eder.{" "}
                  <br />
                  3.7 Ziyaretçi; Site Sahibi’nin kontrolü dışında Site’de yer
                  alan üçüncü tarafa ait reklam ve link gibi içeriklerden Site
                  Sahibi’nin sorumlu olmadığını kabul, beyan ve taahhüt eder.{" "}
                  <br />
                  3.8 Site’nin bakım, onarım yahut güncelleme zamanlarında ya da
                  herhangi bir sistemsel sorunda yahut Site Sahibi’nin kontrolü
                  dışında herhangi bir nedenle kullanım veya erişim sorunları
                  oluşması halinde Site Sahibi ilgili sorunu çözüm için gerekli
                  aksiyonu alacaktır. Bu süre zarfında Ziyaretçi’nin Site’ye
                  yahut Site Sahibi’ne ulaşamaması sebebiyle Site Sahibi’nin
                  herhangi bir sorumluluğu bulunmaz.
                  <br /> 3.9 Ziyaretçi, Site’de yer verilen Site Sahibi’nin
                  iletişim bilgileri, Ziyaretçi’nin Site Sahibi ile iletişim
                  kurması amacıyla paylaşıldığını; bu iletişim bilgilerinin
                  hukuka uygun amaç eylemle kullanılması gerektiğini bildiğini;
                  herhangi bir iletişiminde genel ahlaka ve adaba, hukuka aykırı
                  3. kişilerin haklarını zedeleyen, yanıltıcı, yargılayıcı,
                  saldıran, müstehcen, pornografik, telif haklarına aykırı ve
                  yasa dışı faaliyetleri teşvik eden içerikler üretmeyeceğini,
                  paylaşmayacağını, link vermeyeceğini aksi halinde her türlü
                  sorumluluğun kendisine ait olacağını bildiğini kabul, beyan ve
                  taahhüt eder. <br />
                  3.10 Ziyaretçi, Site’ye erişmek amacıyla kullandığı
                  cihazların/işletim sistemlerinin güvenliğinden, kullandığı
                  cihazlar/iletişim sistemlerinden kaynaklı olarak Site ve/veya
                  üçüncü kişiler nezdinde meydana gelen yahut gelebilecek tüm
                  zararlardan bizzat sorumlu olduğunu kabul, beyan ve taahhüt
                  eder. <br /> 3.11 Ziyaretçi, Site Sahibi tarafından Site’de
                  sunulmuş herhangi bir duyuru, görsel, materyal, ürün veya
                  ürüne ilişkin tanıtım vb. her türlü içeriğin
                  kaldırılabileceğini ve içeriğin kaldırılması da dahil bu
                  kapsamda doğabilecek herhangi bir zarardan Site Sahibi’nin
                  sorumlu olmadığını kabul, beyan ve taahhüt eder. <br />
                  <br /> 4. FİKRİ MÜLKİYET HAKLARININ KORUNMASI <br /> 4.1 Site
                  Sahibi, Site’de yer alan her türlü marka, logo ve bunlarla
                  sınırlı olmamak şartıyla her türlü içeriğin fikri mülkiyet
                  hakkının sahibidir. Site içinde Fikri mülkiyet haklarına
                  aykırı eylemde bulunması halinde Ziyaretçi, 5846 sayılı Fikir
                  ve Sanat Eserleri Kanunu kapsamında hukuki ve cezai yaptırıma
                  maruz kalacağını bildiğini kabul ve beyan eder. <br />
                  4.2 Ziyaretçi, Site’deki görsel, tasarım, ses, kayıt, ibare,
                  grafik, video, metin, kaynak kod, yazılım ile sınırlı
                  olmaksızın Site’de yer alan her türlü materyalin (“Materyal”)
                  kullanım hakkının Site Sahibi’ne ait olduğunu; Materyali
                  kısmen veya tamamen kopyalamayacağını, kullanmayacağını,
                  üçüncü kişilerin kullanımına sunmayacağını veya kullandırmaya
                  çalışmayacağını, Materyal ile yeni bir ürün oluşturmayacağını,
                  tersine mühendislik işlemi yapmayacağını kabul, beyan ve
                  taahhüt eder. Ziyaretçi, aksi Site Sahibi ve Ziyaretçi
                  arasında yazılı olarak kararlaştırılmadıkça Site’de yer alan
                  Materyal’i kişisel veya ticari amaçlarla kullanmamayı yahut
                  Site’de yer alan herhangi bir unsura ilişkin, çevrimiçi
                  herhangi bir platform ya da internet sitesinde link vermemeyi,
                  yayınlamamayı yahut erişim sağlamamayı kabul, beyan ve taahhüt
                  eder. <br />
                  <br /> 5. KİŞİSEL VERİLERİN KORUNMASI Site Sahibi, Ziyaretçi
                  tarafından kendisi ile paylaşılan her türlü kişisel veriyi
                  6698 Sayılı Kişisel Verilerin Korunması Kanunu ve ilgili
                  mevzuat uyarınca işleyecek, saklayacak ve paylaşabilecektir.
                  Site Sahibi, gerçekleştirdiği veri faaliyetlerinde kişisel
                  verilerin korunması amacıyla her türlü teknik ve idari tedbiri
                  alacaktır. Ziyaretçi, Site içerisinde yer alan ve kişisel
                  verilerin işlenmesine ilişkin mevzuat kapsamında açıklamaların
                  yer aldığı Aydınlatma Metni’ni okuduğunu ve kabul ettiğini;
                  veri sahibi olarak ilgili mevzuat kapsamında tanımlanmış
                  haklarını kullanmak istemesi halinde Site Sahibi ile iletişime
                  geçebileceğini bildiğini kabul, beyan ve taahhüt eder. <br />
                  <br /> 6. SORUMLULUK VE TAZMİNAT <br /> 6.1 Site’nin Ziyaretçi
                  tarafından kullanılması sebebiyle Ziyaretçi nezdinde doğmuş
                  doğrudan zararlarından Site Sahibi’nin sorumluluğu kastı ve
                  ağır ihmali ile sınırlıdır. <br /> 6.2 Ziyaretçi, işbu
                  Kullanım Şartlarının ihlali yahut Site dolayısıyla Site
                  Sahibi’nin ve/veya üçüncü kişilerin haklarını ihlal ettiği
                  yahut Site kapsamındaki haklarını kötüye kullanması ve benzeri
                  hallerde ortaya çıkabilecek idari, hukuk, sonuçlarından
                  doğabilecek, ilgili avukatlık ücretleri ve yargılama giderleri
                  dahil her türlü doğrudan veya dolaylı zararlardan sorumlu
                  olacağını ve bu zararı Site Sahibi’nin talebi üzerine tek
                  seferde nakden ve defaten gidereceğini kabul, beyan ve taahhüt
                  eder. <br />
                  <br /> 7. GİZLİLİK <br /> 7.1 Site Sahibi’nin mevzuattan
                  kaynaklanan herhangi bir yükümlülüğü yahut herhangi bir kamu
                  kurum veya kuruluşundan gelebilecek resmi yazılar üzerine,
                  Site kapsamında Ziyaretçi’nin korunması yahut Site’nin ve Site
                  Sahibi’nin faaliyetlerinin bütünlüğünün korumak amacıyla,
                  Ziyaretçi tarafından Site Sahibi ile paylaşılan bilgiler gizli
                  bilgi kapsamında kabul edilmeyecektir. Site Sahibi, mevzuata
                  aykırı iş ve eylemleri, ilgili mercilere bildirimde bulunma
                  hakkını saklı tutar. <br /> 7.2 Üyelik hesabı oluştururken
                  gerçek kişi teyidi alınması için TC kimlik numarası ve cep
                  telefonu numarası bilgileri alınması gerekli olup hiçbir
                  üçüncü taraflarla paylaşılmayacağını, Site Sahibi tarafından
                  veri gizliliği hükümlerine uygun saklanacağını Site Sahibi
                  taahhüt eder. <br />
                  <br /> 8. MUHTELİF HÜKÜMLER <br /> 8.1 Site Sahibi’nin kendi
                  takdirine bağlı olarak işbu Kullanım Şartlarında değişiklik
                  yapma hakkı saklıdır. <br /> 8.3 Site Sahibi tarafından her
                  türlü kayıt (bilgisayar-ses kayıtları gibi manyetik ortamdaki
                  kayıtlar da dahil), işbu Kullanım Şartları kapsamında
                  doğabilecek uyuşmazlıklarda kesin delil olarak kabul edilir.{" "}
                  <br /> 8.4 İşbu Kullanım Şartları ile ilgili olarak yapılacak
                  her türlü bildirim için Ziyaretçi tarafından Site Sahibi’ne
                  bildirilen elektronik posta adresi, Ziyaretçi’nin yasal
                  adresinin isteneceği elektronik posta olarak kabul
                  edilecektir. Ziyaretçi tarafından bildirilen elektronik posta
                  adresi kullanılarak yapılacak her türlü bildirimin, Site
                  Sahibi tarafından elektronik posta gönderilmesinden itibaren 3
                  (üç) gün sonra kullanıcıya ulaştığı kabul edilir. <br />
                  8.5 İşbu Kullanım Şartlar’nın uygulanması, yorumu,
                  geçerliliği, iptali her türlü hukuki değerlendirmesinde Türk
                  Hukuku uygulanacaktır. İşbu Kullanım Şartları’ndan doğabilecek
                  her türlü uyuşmazlık İstanbul Merkez Mahkemeleri ve İcra
                  Dairelerinde çözümlenir. <br /> 8.6 İşbu Kullanım Şartları,
                  Site’de yayımlandığı tarih ile yürürlüğe girer.
                </p>
              </div>
              <div className="mt-3">
                <input
                  type="checkbox"
                  id="acceptCheckbox"
                  checked={checked}
                  onChange={handleCheckboxChange}
                  required
                />
                <label htmlFor="acceptCheckbox" style={{ marginLeft: 10 }}>
                  Sözleşmeyi kabul ediyorum
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setSignShow(false)}>
                Kapat
              </Button>
              <Button
                variant="primary"
                disabled={!checked}
                onClick={() => setSignShow(false)}
              >
                Kabul Et
              </Button>
            </Modal.Footer>
          </Modal>
          <Form.Group>
            <p>Kullanıcı Adı Giriniz:</p>
            <Form.Control
              type="text"
              placeholder="Kullanıcı Adı"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <p>E-Posta Adresinizi Giriniz:</p>
            <Form.Control
              type="text"
              placeholder="E-Posta"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <p>TC No Giriniz:</p>
            <Form.Control
              type="text"
              placeholder="TC No"
              value={tcNo}
              maxLength={11}
              minLength={11}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <p>Doğum Tarihi Giriniz:</p>
            <Form.Control
              type="date"
              placeholder="Doğum Tarihi"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <p>Şifrenizi Giriniz:</p>
            <div style={{ position: "relative" }}>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Şifre"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </Form.Group>
          <Form.Group>
            <p>Telefon Numaranızı Griniz:</p>
            <InputMask
              mask="+90__________"
              replacement={{ _: /\d/ }}
              value={phoneNumber}
              showMask
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="form-control"
              required
            />
          </Form.Group>
          <Form.Group className="login-button">
            <Button variant="primary" type="submit">
              Üye Ol
            </Button>
          </Form.Group>
          <Form.Group
            className="login-button"
            style={{ justifyContent: "flex-end" }}
          >
            <Button variant="primary" onClick={() => setSignShow(true)}>
              Üyelik Sözleşmesi
            </Button>
          </Form.Group>
        </Form>
      </div>

      <Modal show={modalShow}>
        <Modal.Header>Hata!</Modal.Header>
        <Modal.Body className="device-add-wrapper">
          <p>{errors}</p>
          <div className="button-wrapper">
            <Button
              variant="secondary"
              onClick={() => {
                setModalShow(false);
                setErrors("");
              }}
            >
              Kapat
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SignIn;
