import { useContext, useState, useEffect } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import BedCard from "../../components/BedCard/BedCard";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Sidebar from "../../components/Sidebar/Sidebar";

import { useParams } from "react-router-dom";

import "./style.css";

const NursingHomeDetail = () => {
  const { sidebarOpen, bedData } = useContext(DasboardContext);
  const [allOrg, setAllOrg] = useState();
  const [foundOrg, setFoundOrg] = useState();
  const [filteredData, setFilteredData] = useState();

  const unSlug = (slug) => {
    const withoutDash = slug.replace(/-/g, " ");
    const formattedText = withoutDash.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );

    return formattedText;
  };

  const params = useParams();

  const getOrganisations = async () => {
    try {
      const response = await fetch(
        `https://app.vivasmartcare.com/api/organisations`
      );
      const organisations = await response.json();
      setAllOrg(await organisations);
      setFoundOrg(
        await organisations.find(
          (org) => org.organisationName === unSlug(params.homename)
        )
      );
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    getOrganisations();
  }, []);

  return (
    <>
      <Sidebar />
      <Breadcrumb />
      <div
        className={`device-list component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <h3>{foundOrg?.organisationName}</h3>
        <p className="authorized-person">
          Kurum Yetkilisi:{" "}
          {foundOrg?.organisationAuthorizedPerson.map((person, index) => (
            <span key={index}>{person}</span>
          ))}
        </p>
        <p>Kurum Telefon Numarası: {foundOrg?.organisationPhone}</p>
        <div className="bed-card-wrapper">
          {bedData
            .filter((data) => data.organization === unSlug(params.homename))
            .map((bed, index) => (
              <BedCard bed={bed} key={index} />
            ))}
        </div>
      </div>
    </>
  );
};

export default NursingHomeDetail;
