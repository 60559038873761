import { useLocation } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import "./style.css";
import { useContext } from "react";
import { DasboardContext } from "../../context/dashboard-context";

const Breadcrumb = ({ showPath }) => {
  const location = useLocation();
  const path = location.pathname;

  const { sidebarOpen, setSidebarOpen, profileName, userInfo, setUserInfo } =
    useContext(DasboardContext);

  return (
    <div className={`breadcrumb-wrapper ${sidebarOpen ? "" : "close"}`}>
      <div>
        <AiOutlineMenu onClick={() => setSidebarOpen(!sidebarOpen)} />
        {showPath !== "show" && (
          <p>{path == "/" ? "ANASAYFA" : path.substring(1).toUpperCase()}</p>
        )}
      </div>
      <div>
        <p>Hoşgeldiniz, {userInfo?.username}.</p>
      </div>
    </div>
  );
};

export default Breadcrumb;
