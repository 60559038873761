import { useContext } from "react";
import { DasboardContext } from "../../context/dashboard-context";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DeviceDetailScreen from "../../components/DeviceDetailScreen/DeviceDetailScreen";

const DeviceDetail = () => {
  const { sidebarOpen } = useContext(DasboardContext);

  return (
    <>
      <Breadcrumb showPath={"show"} />
      <div
        className={`device-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <div className="user-detail-wrapper">
          <DeviceDetailScreen />
        </div>
      </div>
    </>
  );
};

export default DeviceDetail;
