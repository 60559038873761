import { pdfjs } from "react-pdf";
import { React, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import Alarms from "./pages/Alarms/Alarms";
import Sidebar from "./components/Sidebar/Sidebar";
import Devices from "./pages/Devices/Devices";
import UsersList from "./pages/Users/UsersList";
import TaskList from "./pages/TaskList/TaskList";
import Organisation from "./pages/Organisation/Organisation";
import UserDetail from "./pages/UserDetail/UserDetail";
import Reports from "./pages/Reports/Reports";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import NursingHomeDetail from "./pages/NursingHomeDetail/NursingHomeDetail";
import Login from "./pages/Login/Login";
import DeviceDetail from "./pages/DeviceDetail/DeviceDetail";
import OrganisationDetail from "./pages/OrganisationDetail/OrganisationDetail";
import SignIn from "./pages/SignIn/SignIn";
import LoginDetail from "./pages/LoginDetail/LoginDetail";

import { DashboardProvider } from "./context/dashboard-context";

import Cookies from "js-cookie";
const token = Cookies.get("token");

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const AppRoutes = () => {
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token !== undefined) {
      fetch(`https://app.vivasmartcare.com/api/profile/${token}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }).then((response) => {
        response.json().then((userInfo) => {
          setUserInfo(userInfo);
        });
      });
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="main-wrapper">
              <Login />
            </div>
          }
        />
        <Route
          path="/homepage"
          element={
            <div className="main-wrapper">
              <App />
            </div>
          }
        />
        <Route
          path="/login"
          element={
            <div className="main-wrapper">
              <Login />
            </div>
          }
        />
        <Route
          path="/nursing-home-detail/:homename"
          element={<NursingHomeDetail />}
        />
        <Route
          path="/task-list"
          element={
            <>
              <Sidebar />
              <TaskList />
            </>
          }
        />
        <Route
          path="/alarms"
          element={
            <>
              <Sidebar />
              <Alarms />
            </>
          }
        />
        <Route
          path="/devices"
          element={
            <>
              <Sidebar />
              <Devices />
            </>
          }
        />
        <Route
          path="/devices/:id"
          element={
            <>
              <Sidebar />
              <DeviceDetail />
            </>
          }
        />
        <Route
          path="/reports"
          element={
            <>
              <Sidebar />
              <Reports />
            </>
          }
        />
        <Route
          path="/organisation"
          element={
            <>
              <Sidebar />
              <Organisation />
            </>
          }
        />
        <Route
          path="/organisation/:id"
          element={
            <>
              <Sidebar />
              <OrganisationDetail />
            </>
          }
        />
        <Route
          path="/user-list"
          element={
            <>
              <Sidebar />
              <UsersList />
            </>
          }
        />
        <Route
          path="/user-list/:id"
          element={
            <>
              <Sidebar />
              <UserDetail />
            </>
          }
        />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route
          path="/login-detail"
          element={
            <>
              <Sidebar />
              <LoginDetail />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DashboardProvider>
    <AppRoutes />
  </DashboardProvider>
);
