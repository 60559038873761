import { useContext, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { InputMask } from "@react-input/mask";

import "./style.css";
import { Link } from "react-router-dom";
import { DasboardContext } from "../../context/dashboard-context";

const UserTable = ({ tableRow, tableData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [nameSurname, setNameSurname] = useState("");
  const [userName, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userType, setUserType] = useState("");
  const [active, setActive] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [emergencyContacts, setEmergencyContacts] = useState("");
  const [anamnesis, setAnamnesis] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [devicesUsed, setDevicesUsed] = useState("");
  const [devicesIDs, setDevicesIDs] = useState("");

  const { bedData } = useContext(DasboardContext);

  async function addUser(e) {
    e.preventDefault();

    setBtnDisabled(true);
    setShowSpinner(true);

    try {
      const response = await fetch("https://app.vivasmartcare.com/api/users-add", {
        method: "POST",
        body: JSON.stringify({
          nameSurname,
          userName,
          phoneNumber,
          userType,
          active,
          birthDay,
          gender,
          address,
          addressLink,
          emergencyContacts,
          anamnesis,
          paymentStatus,
          devicesUsed,
          devicesIDs,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        setNameSurname("");
        setUsername("");
        setUserType("");
        setActive("");
        setBirthDay("");
        setGender("");
        setAddress("");
        setAddressLink("");
        setEmergencyContacts("");
        setAnamnesis("");
        setPaymentStatus("");
        setDevicesUsed("");
        setDevicesIDs("");
        alert("Kişi eklendi");
        window.location.reload();
      } else {
        alert("Personel eklenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Ekleme işlemi sırasında bir hata oluştu:", error);
    } finally {
      setBtnDisabled(false);
      setShowSpinner(false);
    }
  }

  return (
    <div className="user-table">
      <div className="users-filter-inputs">
        <Form.Group>
          <Form.Control type="text" placeholder="Müşteri İsmine Göre Ara" />
        </Form.Group>
        <Form.Group>
          <Form.Select>
            <option>Kullanıcı Tipi</option>
            <option value="1">Ev Takibi</option>
            <option value="1">Kurum Takibi</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Button variant="primary" onClick={handleShow}>
            Kullanıcı Ekle
          </Button>
        </Form.Group>
      </div>
      <Table striped bordered hover responsive className="alarm-table">
        <thead>
          <tr>
            {tableRow.map((tableRow, index) => (
              <th key={index}>{tableRow}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index} onClick={handleShow}>
              <td>{data.nameSurname}</td>
              <td>{data.userType}</td>
              <td className="see-profile">
                <Link
                  to={{
                    pathname: `/user-list/${data._id}`,
                  }}
                >
                  Profili Görüntüle
                </Link>
              </td>
              <td className="see-profile">
                <Link
                  to={{
                    pathname: "/reports",
                  }}
                >
                  Kullanıcı Raporu
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Kullanıcı Ekle</Modal.Header>
        <Modal.Body className="device-add-wrapper">
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="İsim Soyisim Giriniz:"
                value={nameSurname}
                onChange={(e) => setNameSurname(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <InputMask
                mask="+90__________"
                replacement={{ _: /\d/ }}
                value={phoneNumber}
                className="form-control"
                placeholder="Telefon No Giriniz:"
                showMask
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Kullanıcı Adı Giriniz:"
                value={userName}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="mb-2">Kullanıcı Tipi Seçiniz</label>
              <Form.Select
                onChange={(e) => setUserType(e.target.value)}
                value={userType}
              >
                <option value="Kurum Takibi">Kurum Takibi</option>
                <option value="Ev Takibi">Ev Takibi</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <label className="mb-2">Kullanıcı Durumunu Seçiniz</label>
              <Form.Select
                onChange={(e) => setActive(e.target.value)}
                value={active}
              >
                <option value="Aktif">Aktif</option>
                <option value="Pasif">Pasif</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <label htmlFor="birthday">Doğum Tarihinizi Seçiniz:</label>
              <Form.Control
                type="date"
                id="birthday"
                value={birthDay}
                onChange={(e) => setBirthDay(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="mb-2">Cinsiyet Seçiniz</label>
              <Form.Select
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option value="Erkek">Erkek</option>
                <option value="Kadın">Kadın</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Adresinizi Giriniz:"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Adres Google Maps Linki Giriniz:"
                value={addressLink}
                onChange={(e) => setAddressLink(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Acil Durum Kişisi Giriniz: örn(Ali Veli 531 237 90 00)"
                value={emergencyContacts}
                onChange={(e) => setEmergencyContacts(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Anamnez Giriniz:"
                value={anamnesis}
                onChange={(e) => setAnamnesis(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="mb-2">Ödeme Durumunu Seçiniz</label>
              <Form.Select
                onChange={(e) => setPaymentStatus(e.target.value)}
                value={paymentStatus}
                id="payment"
              >
                <option value="Aktif">Aktif</option>
                <option value="Pasif">Pasif</option>
                <option value="Demo">Demo</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <label className="mb-2">Cihaz Markasını Seçiniz</label>
              <Form.Select
                onChange={(e) => setDevicesUsed(e.target.value)}
                value={devicesUsed}
              >
                <option value="Aktif">Vayyar</option>
                <option value="Pasif">Minifinder</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <label className="mb-2">Cihaz ID'si Seçiniz</label>
              <Form.Select
                onChange={(e) => setDevicesIDs(e.target.value)}
                value={devicesIDs}
              >
                {bedData.map((bed, index) => (
                  <option value={bed.deviceID} key={index}>
                    {bed.deviceID}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <div className="button-wrapper">
              <Button variant="secondary" onClick={handleClose}>
                İptal
              </Button>
              <Button
                variant="primary"
                onClick={addUser}
                className={`${btnDisabled === true && "disabled"}`}
              >
                Ekle
              </Button>
              {showSpinner && <Spinner animation="border" variant="primary" />}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserTable;
