import Table from "react-bootstrap/Table";

import "./style.css";

const ReportsDetailTable = ({ tableTD, tableData }) => {
  return (
    <div className="reports-detail-table">
      <Table striped bordered hover responsive className="alarm-table">
        <thead>
          <tr>
            {tableTD.map((tableRow, index) => (
              <th key={index}>{tableRow}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td>{data.timeZone}</td>
              <td>{data.suitName}</td>
              <td className="red">{data.eventType}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ReportsDetailTable;
