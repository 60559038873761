import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const LastFiveAlarm = ({ id, closeLastAlarmsModal, lat, long }) => {
  const [alarm, setAlarm] = useState([]);

  const [show, setShow] = useState(true);
  const [alarmShow, setAlarmShow] = useState(true);

  function convertDate(date) {
    var d = new Date(date * 1000);
    var month = d.getMonth() + 1;
    month = month < 10 ? "0" + month : month;

    var day = d.getDate();
    day = day < 10 ? "0" + day : day;

    var year = d.getFullYear();

    var hours = d.getHours();
    hours = hours < 10 ? "0" + hours : hours;

    var minutes = d.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;

    var seconds = d.getSeconds();
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return (
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }

  async function getAlarmDetail() {
    try {
      const response = await fetch(
        `https://app.vivasmartcare.com/api/alarm-detail/${id}`
      );
      const data = await response.json();
      const lastFiveAlarms = data.slice(-5);
      setAlarm(lastFiveAlarms);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  }

  useEffect(() => {
    getAlarmDetail();
  }, [id]);

  if (alarm.length < 1)
    return (
      <Modal show={show}>
        <Modal.Header>Alarm Kaydı Bulunamadı</Modal.Header>
        <Modal.Body className="device-add-wrapper">
          <p>Bu cihaza ait herhangi bir alarm kaydı bulunamadı!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Kapat</Button>
        </Modal.Footer>
        {lat !== null && long !== null && (
          <iframe
            src={`https://maps.google.com/?q=${lat},${long}`}
            width="100%"
            height="400"
            allowFullScreen
            contextMenu=","
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        )}
      </Modal>
    );

  return (
    <Modal show={alarmShow}>
      <Modal.Header>Son Alınan Alarmlar</Modal.Header>
      <Modal.Body>
        <Table striped bordered hover responsive className="alarm-table">
          <thead>
            <tr>
              <th>Alarm Tipi</th>
              <th>Açıklama</th>
              <th>Teyit Durumu</th>
              <th>Tarih</th>
            </tr>
          </thead>
          <tbody>
            {alarm?.map((detail, index) => (
              <tr key={index}>
                <td>{detail.eventType}</td>
                <td>{detail.description}</td>
                <td>{detail.confirmation}</td>
                <td>{convertDate(detail.endTimestamp)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeLastAlarmsModal}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LastFiveAlarm;
