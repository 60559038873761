import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import logo from "../../assets/images/logo.png";

const ForgotPassword = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="login-wrapper">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <h3>Şifre Yenileme Ekranı</h3>
        <div className="form-wrapper">
          <Form.Group>
            <p>Kullanıcı Adı:</p>
            <Form.Control type="text" placeholder="Kullanıcı Adı" />
          </Form.Group>
          <Form.Group className="login-button">
            <Button
              variant="primary"
              onClick={handleShow}
              style={{ width: "100%" }}
            >
              Aktivasyon Linki Gönder
            </Button>
          </Form.Group>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <p>
            Mail adresinize aktivasyon kodu başarılı bir şekilde gönderilmiştir.
            İşleminize mail adresinizden devam edebilirsiniz.
          </p>
          <div className="button-wrapper">
            <Button variant="primary" onClick={handleClose}>
              Kapat
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPassword;
