import { useContext, useEffect, useState } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import AlarmTable from "../../components/AlarmTable/AlarmTable";

import "./style.css";

const Alarms = () => {
  const [tableData, setTableData] = useState([]);
  const { bedData, userInfo } = useContext(DasboardContext);
  const tableTD = [
    "Saat Dilimi",
    "Süit Adı",
    "Oda Adı",
    "Olay Türü",
    "Cihaz Id",
    "Aranma Sayısı",
    "Teyit Durumu",
    "Açıklama",
    "Alarm Senaryosu",
    "İşlemi Yapan Kullanıcı",
  ];

  const getAlarmLogs = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/alarm-logs`);
      const alarmLogs = await response.json();
      setTableData(alarmLogs.filter((alarm) => alarm.resolved === "true"));
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    getAlarmLogs();
  }, []);

  const { sidebarOpen } = useContext(DasboardContext);
  return (
    <>
      <Breadcrumb />
      <div
        className={`alarms-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <AlarmTable
          tableTD={tableTD}
          tableData={tableData}
          bedData={bedData}
          userInfo={userInfo}
        />
      </div>
    </>
  );
};

export default Alarms;
