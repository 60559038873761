import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { InputMask } from "@react-input/mask";

import "./style.css";
import { DasboardContext } from "../../context/dashboard-context";

const DeviceDetailScreen = () => {
  const { usernames } = useContext(DasboardContext);
  const [deviceDetail, setDeviceDetail] = useState([]);

  const [deviceID, setDeviceID] = useState("");
  const [organization, setOrganization] = useState("");
  const [person, setPerson] = useState("");
  const [status, setStatus] = useState("");
  const [brand, setBrand] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [location, setLocation] = useState("");
  const [room, setRoom] = useState("");
  const [floor, setFloor] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomName, setRoomName] = useState("");
  const [smsPhone, setSmsPhone] = useState([""]);
  const [suiteName, setSuiteName] = useState("");
  const [deviceNote, setDeviceNote] = useState("");
  const [username, setUsername] = useState("");
  const [isDemo, setIsDemo] = useState();

  const [orgName, setOrgName] = useState();

  //For room count and floor count
  const [org, setOrg] = useState();
  const [floorCount, setFloorCount] = useState(0);
  const [roomCount, setRoomCount] = useState(0);

  const locations = ["Kurum", "Ev", "Depo", "Bakım"];
  const deviceStatus = ["Aktif", "Pasif", "Teknik Arıza"];
  const roomTypeSelect = [
    "Banyo",
    "Yatak Odası",
    "Aile Odası",
    "Koridor",
    "Mutfak",
    "Yemek Odası",
    "Oturma Odası",
    "Diğer",
  ];

  const addPhoneNumber = () => {
    if (smsPhone.length < 4) {
      setSmsPhone([...smsPhone, ""]);
    }
  };

  const removePhoneNumber = (index) => {
    setSmsPhone(smsPhone.filter((_, i) => i !== index));
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...smsPhone];
    newPhoneNumbers[index] = value;
    setSmsPhone(newPhoneNumbers);
  };

  const { id } = useParams();

  const getOrganisations = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/organisations`);
      const organisationName = await response.json();
      setOrgName(organisationName);
      console.log(organisationName);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  async function updateDevice() {
    try {
      const response = await fetch("https://app.vivasmartcare.com/api/device-update", {
        method: "PUT",
        body: JSON.stringify({
          id,
          deviceID,
          organization,
          person,
          status,
          brand,
          purchaseDate,
          installationDate,
          location,
          floor,
          room,
          roomName,
          smsPhone,
          suiteName,
          deviceNote,
          roomType,
          isDemo,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        alert("Cihaz Güncellendi");
        fetchData();
      } else {
        alert("Cihaz güncellenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Güncelleme işlemi sırasında bir hata oluştu:", error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/devices/${id}`);
      const devices = await response.json();
      setDeviceDetail(devices);
      setDeviceID(devices.deviceID);
      setOrganization(devices.organization);
      setPerson(devices.person);
      setStatus(devices.status);
      setBrand(devices.brand);
      setPurchaseDate(devices.purchaseDate);
      setInstallationDate(devices.installationDate);
      setLocation(devices.location);
      setUsername(devices.person);
      setFloor(devices.floor);
      setRoom(devices.room);
      setRoomName(devices.roomName);
      setSmsPhone(devices.smsPhone);
      setSuiteName(devices.suiteName);
      setDeviceNote(devices.deviceNote);
      setRoomType(devices.roomType);
      setIsDemo(devices.isDemo);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  async function deleteDevice() {
    try {
      const response = await fetch("https://app.vivasmartcare.com/api/device", {
        method: "DELETE",
        body: JSON.stringify({
          id,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        alert("Cihaz Silindi");
        window.location.href = "/devices";
      } else {
        alert("Cihaz silinirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Silme işlemi sırasında bir hata oluştu:", error);
    }
  }

  useEffect(() => {
    getOrganisations();
  }, []);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const selectedOrg = orgName?.find(
      (org) => org.organisationName === organization
    );
    setOrg(selectedOrg);
    setFloorCount(parseInt(selectedOrg?.organisationFloors));
    setRoomCount(parseInt(selectedOrg?.organisationRooms));
  }, [organization]);

  return (
    <div className="user-detail-screen">
      <div className="device-info-wrapper">
        <div className="info">
          <span>Cihaz ID:</span>
          <Form.Control
            type="text"
            value={deviceID}
            onChange={(e) => setDeviceID(e.target.value)}
          />
        </div>
        <div className="info">
          <span>Kurum Adı:</span>
          <Form.Select onChange={(e) => setOrganization(e.target.value)}>
            {orgName?.map((org, index) => (
              <option
                value={org.organisationName}
                key={index}
                selected={org.organisationName === organization && "true"}
              >
                {org.organisationName}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="info">
          <span>Cihaz Markası</span>
          <Form.Control
            type="text"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
        </div>
        <div className="info">
          <span>Kullanıcı Adı:</span>
          <Form.Select onChange={(e) => setPerson(e.target.value)}>
            {usernames?.map((username, index) => (
              <option
                value={username.userName}
                key={index}
                selected={username.userName === person && "true"}
              >
                {username.userName}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="info">
          <span>Cihaz Durumu:</span>
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            {deviceStatus.map((dStatu, index) => (
              <option
                value={dStatu}
                key={index}
                selected={status === dStatu && "true"}
              >
                {dStatu}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="info">
          <span>Satın Alma Tarihi</span>
          <Form.Control
            type="text"
            value={purchaseDate}
            onChange={(e) => setPurchaseDate(e.target.value)}
          />
        </div>
        <div className="info">
          <span>Kurulum Tarihi:</span>
          <Form.Control
            type="text"
            value={installationDate}
            onChange={(e) => setInstallationDate(e.target.value)}
          />
        </div>
        <div className="info">
          <span>Konum:</span>
          <Form.Select onChange={(e) => setLocation(e.target.value)}>
            {locations.map((loc, index) => (
              <option
                value={loc}
                key={index}
                selected={location === loc ? "true" : "false"}
              >
                {loc}
              </option>
            ))}
          </Form.Select>
        </div>
        {location === "Kurum" && (
          <div className="info">
            <span>Süit Bilgisi:</span>
            <Form.Control
              type="text"
              placeholder="Süit Bilgisi"
              value={suiteName}
              onChange={(e) => setSuiteName(e.target.value)}
            />
          </div>
        )}
        <div className="info">
          <span>Bulunduğu Kat:</span>
          <Form.Select
            required
            value={floor}
            onChange={(e) => setFloor(e.target.value)}
          >
            <option>Kat Seçiniz:</option>
            {floorCount &&
              floorCount > 0 &&
              [...Array(floorCount).keys()].map((floorCount, index) => (
                <option
                  key={index + 1}
                  value={index + 1}
                  selected={floor == index + 1}
                >
                  {index + 1}. Kat
                </option>
              ))}
          </Form.Select>
        </div>
        {location === "Kurum" || location === "Ev" ? (
          <div className="info">
            <span>Oda Tipi</span>
            <Form.Select
              required
              value={roomType}
              onChange={(e) => setRoomType(e.target.value)}
            >
              {roomTypeSelect.map((room, index) => (
                <option value={room} key={index} selected={room === roomType}>
                  {room}
                </option>
              ))}
            </Form.Select>
          </div>
        ) : (
          <div className="info">
            <span>Bulunduğu Oda:</span>
            <Form.Select
              required
              value={room}
              onChange={(e) => setRoom(e.target.value)}
            >
              <option>Oda Seçiniz:</option>
              {roomCount &&
                roomCount > 0 &&
                [...Array(roomCount).keys()].map((roomCount, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                    selected={room == index + 1}
                  >
                    {index + 1}. Oda
                  </option>
                ))}
            </Form.Select>
          </div>
        )}
        <div className="info">
          <span>Oda İsmi:</span>
          <Form.Control
            type="text"
            required
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </div>
        <div className="info">
          <div>
            <span>Bildirim Alan Numara:</span>
            {smsPhone.map((phoneNumber, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <InputMask
                  mask="+90__________"
                  replacement={{ _: /\d/ }}
                  value={phoneNumber}
                  showMask
                  onChange={(e) =>
                    handlePhoneNumberChange(index, e.target.value)
                  }
                />
                {smsPhone.length < 4 && (
                  <Button
                    onClick={addPhoneNumber}
                    style={{ marginTop: "0", marginLeft: "10px" }}
                  >
                    Ekle
                  </Button>
                )}
                {index > 0 && (
                  <Button
                    onClick={() => removePhoneNumber(index)}
                    style={{ marginLeft: "10px", marginTop: "0" }}
                  >
                    Çıkart
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="info device-note">
          <span>Cihaz Notları:</span>
          <Form.Control
            type="text"
            required
            value={deviceNote}
            onChange={(e) => setDeviceNote(e.target.value)}
          />
        </div>
        <div className="info is-demo">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Bu bir demo cihazıdır"
            checked={isDemo}
            onChange={(e) => setIsDemo(e.target.checked)}
          />
        </div>
        <div className="button-wrapper">
          <Button variant="primary" onClick={updateDevice}>
            Kaydet - Güncelle
          </Button>
          {/* <Button
            variant="danger"
            onClick={() => {
              const userConfirmed = window.confirm(
                "Cihaz kalıcı olarak silinecektir. Emin misiniz?"
              );

              if (userConfirmed) {
                deleteDevice();
              }
            }}
          >
            Cihazı Sil
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default DeviceDetailScreen;
