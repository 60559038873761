import { useContext, useState, useEffect } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import UserTable from "../../components/UserTable/UserTable";

import "./style.css";

const UsersList = () => {
  const tableRow = ["Kullanıcı Adı", "Kullanıcı Tipi", "Profil", "Raporlar"];
  const [tableData, setTableData] = useState([]);

  async function getUser() {
    try {
      await fetch(`https://app.vivasmartcare.com/api/users`).then(
        (response) => {
          response.json().then((users) => {
            setTableData(users);
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  const { sidebarOpen } = useContext(DasboardContext);

  return (
    <>
      <Breadcrumb />
      <div
        className={`user-list-wrapper component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        <UserTable tableRow={tableRow} tableData={tableData} />
      </div>
    </>
  );
};

export default UsersList;
