import { useContext, useEffect, useState } from "react";

import { MdWatch } from "react-icons/md";
import { BsFillHeartPulseFill } from "react-icons/bs";
import { FaToilet, FaHome, FaWarehouse, FaBed } from "react-icons/fa";
import { GrHostMaintenance } from "react-icons/gr";
import { FaCode } from "react-icons/fa6";
import { CgOrganisation } from "react-icons/cg";
import { MdSignalWifiConnectedNoInternet2 } from "react-icons/md";
import { IoIosWifi } from "react-icons/io";
import { FcFullBattery } from "react-icons/fc";
import { FcHighBattery } from "react-icons/fc";
import { FcLowBattery } from "react-icons/fc";
import { FcEmptyBattery } from "react-icons/fc";

import "./style.css";

import { Link } from "react-router-dom";
import slugify from "react-slugify";
import { DasboardContext } from "../../context/dashboard-context";
import AlarmModal from "../AlarmModal/AlarmModal";
import LastFiveAlarm from "../LastFiveAlarm/LastFiveAlarm";

const BedCard = ({ bed }) => {
  const [show, setShow] = useState(false);
  const [lastAlarmsShow, setLastAlarmsShow] = useState(false);

  const [alarms, setAlarms] = useState();
  const [userDetail, setUserDetail] = useState([]);

  const [alarm, setAlarm] = useState();

  const [minidata, setMinidata] = useState([]);

  const handleShow = () => setShow(true);

  const closeModal = () => {
    setShow(false);
  };

  const closeLastAlarmsModal = () => {
    setLastAlarmsShow(false);
  };

  const { states, events, topic } = useContext(DasboardContext);

  const [bedStates, setBedStates] = useState();
  const [bedEvents, setBedEvents] = useState();

  const symbols = {
    Mutfak: "countertops",
    Banyo: "bathtub",
    "Yatak Odası": "king_bed",
    "Yemek Odası": "table_restaurant",
    "Oturma Odası": "scene",
  };

  function extractIdFromUrl() {
    const parts = topic?.split("/");
    const idPart = parts?.find((part) => part.startsWith("id_"));
    if (idPart) {
      return idPart;
    } else {
      return null;
    }
  }

  async function getAlarmDetail() {
    try {
      const response = await fetch(
        `https://app.vivasmartcare.com/api/alarm-logs/${bed.deviceID}`
      );
      setAlarms(await response.json());
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  }

  async function getUserDetails() {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/user/${bed.person}`);
      setUserDetail(await response.json());
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  }

  // async function getMinifinderDetail() {
  //   try {
  //     const response = await fetch(
  //       `https://alarm.minifinder.com/vivasmarttech/index.php`
  //     );
  //     const minidata = await response.json();

  //     minidata.forEach((item) => {
  //       if (item.imei === bed.deviceID) {
  //         setMinidata(item);
  //       }
  //     });

  //     const deviceId = bed.deviceID;
  //     try {
  //       const response = await fetch(
  //         `https://app.vivasmartcare.com/api/minifinder-log`,
  //         {
  //           method: "POST",
  //           body: JSON.stringify({ deviceId, minidata }),
  //           headers: { "Content-Type": "application/json" },
  //         }
  //       );
  //       const responseData = await response.json();
  //       console.log(responseData);
  //     } catch (error) {
  //       console.error("Veri eklenirken bir hata oluştu:", error);
  //     }
  //   } catch (error) {
  //     console.error("Veri getirilirken bir hata oluştu:", error);
  //   }
  // }

  useEffect(() => {
    getAlarmDetail();
    getUserDetails();
    // if (bed.brand === "Minifinder") {
    //   const intervalId = setInterval(getMinifinderDetail, 30 * 1000);
    //   return () => clearInterval(intervalId);
    // }
  }, []);

  useEffect(() => {
    if (states.deviceId === bed.deviceID) {
      setBedStates(states);
      setBedEvents(events);
    }
  }, [topic]);

  return (
    <>
      <div className="bed-card">
        <h3>
          {/* {bed.person
            .split(" ")
            .map((word) => word.charAt(0))
            .join(".")} */}
          {bed.person}
        </h3>
        <div className="alarms">
          {alarms?.map((alarm, index) => (
            <div
              key={index}
              className={`alarm-type red`}
              onClick={() => {
                handleShow();
                setAlarm(alarm);
              }}
            >
              {alarm.eventType === "calling" && "Düşme Alarmı"}
            </div>
          ))}
        </div>

        {bed.organization && (
          <Link
            className="organisation-name"
            to={`/nursing-home-detail/${slugify(bed.organization)}`}
          >
            {bed.organization}
          </Link>
        )}

        {bed.suiteName && bed.suiteName !== "" && (
          <div className="room-name suite">{bed.suiteName}</div>
        )}

        <div className="room-name">{bed.roomName}</div>

        <div
          className="device-container"
          onClick={() => {
            setLastAlarmsShow(true);
          }}
        >
          <div className="device-wrapper">
            {bed.brand === "Vayyar" ? (
              bed.location === "Banyo" ? (
                <FaToilet />
              ) : bed.location === "Ev" ? (
                <FaHome />
              ) : bed.location === "Depo" ? (
                <FaWarehouse />
              ) : bed.location === "Bakım" ? (
                <GrHostMaintenance />
              ) : bed.location === "Kurum" ? (
                <CgOrganisation />
              ) : bed.location === "Demo" ? (
                <FaCode />
              ) : (
                ""
              )
            ) : (
              <MdWatch />
            )}

            {events &&
              bed.brand !== "Minifinder" &&
              (bedEvents?.payload?.presenceDetected === true ? (
                bed.roomType !== "Banyo" ? (
                  <span className="material-symbols-rounded in-bed">
                    {symbols[bed.roomType]}
                  </span>
                ) : (
                  <FaToilet />
                )
              ) : bed.roomType !== "Banyo" ? (
                <span className="material-symbols-rounded">
                  {symbols[bed.roomType]}
                </span>
              ) : (
                <FaToilet />
              ))}

            {/* Minifinder Battery Level with icon */}
            {minidata && bed.brand === "Minifinder" && (
              <div className="battery-status">
                {minidata?.lmsg?.battery_lvl > 90 && <FcFullBattery />}
                {minidata?.lmsg?.battery_lvl > 50 &&
                  minidata?.lmsg?.battery_lvl < 89 && <FcHighBattery />}
                {minidata?.lmsg?.battery_lvl > 15 &&
                  minidata?.lmsg?.battery_lvl < 49 && <FcLowBattery />}
                {minidata?.lmsg?.battery_lvl === 0 && <FcEmptyBattery />}
                <p>{minidata?.lmsg?.battery_lvl}%</p>
              </div>
            )}

            {/* Vayyar Region Map for bed  */}
            {events &&
              bed.brand !== "Minifinder" &&
              bed.roomType === "Yatak Odası" &&
              bedEvents?.payload?.presenceRegionMap[0] === 1 && <FaBed />}

            {/* Vayyar Online Offline */}
            {bed.brand === "Vayyar" &&
              (bedStates?.status &&
              (bedStates?.status === "learning" ||
                bedStates?.status === "test" ||
                bedStates?.status === "monitoring") ? (
                <IoIosWifi fill="green" />
              ) : (
                <>
                  <MdSignalWifiConnectedNoInternet2 fill="red" />
                </>
              ))}

            {/* Minifinder Online Offline */}
            {bed.brand === "Minifinder" &&
              (minidata?.lmsg?.battery_lvl === 0 ? (
                <MdSignalWifiConnectedNoInternet2 fill="red" />
              ) : (
                <IoIosWifi fill="green" />
              ))}
          </div>
        </div>

        {bed.vital === true && (
          <div className="vital-container">
            <Link to="/">
              <BsFillHeartPulseFill color="#00A3E0" size={32} />
            </Link>
          </div>
        )}
      </div>

      {show === true && (
        <AlarmModal
          alarm={alarm}
          userDetail={userDetail}
          bed={bed}
          closeModal={closeModal}
        />
      )}

      {lastAlarmsShow === true && (
        <LastFiveAlarm
          id={bed.deviceID}
          closeLastAlarmsModal={closeLastAlarmsModal}
          lat={minidata?.lat || null}
          long={minidata?.long || null}
        />
      )}
    </>
  );
};

export default BedCard;
