import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiFillDelete } from "react-icons/ai";

import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import { DasboardContext } from "../../context/dashboard-context";

import "./style.css";

const OrganisationDetailScreen = () => {
  const { id } = useParams();
  const { usernames } = useContext(DasboardContext);
  const [organisation, setOrganisation] = useState();

  const [organisationName, setOrganisationName] = useState("");
  const [organisationMail, setOrganisationMail] = useState("");
  const [organisationPhone, setOrganisationPhone] = useState("");
  const [organisationAuthorizedPerson, setOrganisationAuthorizedPerson] =
    useState([]);
  const [organisationFloors, setOrganisationFloors] = useState("");
  const [organisationRooms, setOrganisationRooms] = useState("");
  const [organisationBilling, setOrganisationBilling] = useState("");
  const [
    organisationAuthorizedPersonActive,
    setOrganisationAuthorizedPersonActive,
  ] = useState("");
  const [organisationActive, setOrganisationActive] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePersonClick = (index) => {
    const updatedPersons = organisationAuthorizedPerson.filter(
      (_, i) => i !== index
    );
    setOrganisationAuthorizedPerson(updatedPersons);
  };

  const handleAddToList = () => {
    if (
      selectedOption &&
      !organisationAuthorizedPerson.includes(selectedOption)
    ) {
      setOrganisationAuthorizedPerson([
        ...organisationAuthorizedPerson,
        selectedOption,
      ]);
    }
  };

  const getOrganisation = async () => {
    try {
      const response = await fetch(
        `https://app.vivasmartcare.com/api/organisation/${id}`
      );
      const allOrganisation = await response.json();
      setOrganisation(allOrganisation);
      setOrganisationName(allOrganisation.organisationName);
      setOrganisationMail(allOrganisation.organisationMail);
      setOrganisationPhone(allOrganisation.organisationPhone);
      setOrganisationAuthorizedPerson(
        allOrganisation.organisationAuthorizedPerson
      );
      setOrganisationAuthorizedPerson(
        allOrganisation.organisationAuthorizedPerson
      );
      setOrganisationFloors(allOrganisation.organisationFloors);
      setOrganisationRooms(allOrganisation.organisationRooms);
      setOrganisationBilling(allOrganisation.organisationBilling);
      setOrganisationAuthorizedPersonActive(
        allOrganisation.organisationAuthorizedPersonActive
      );
      setOrganisationActive(allOrganisation.organisationActive);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    getOrganisation();
  }, [id]);

  async function updateOrganization() {
    try {
      const response = await fetch(
        "https://app.vivasmartcare.com/api/organisation",
        {
          method: "PUT",
          body: JSON.stringify({
            id,
            organisationName,
            organisationMail,
            organisationPhone,
            organisationAuthorizedPerson,
            organisationFloors,
            organisationRooms,
            organisationBilling,
            organisationAuthorizedPersonActive,
            organisationActive,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.ok) {
        alert("Organizasyon Güncellendi");
        getOrganisation();
      } else {
        alert("Organizasyon güncellenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Güncelleme işlemi sırasında bir hata oluştu:", error);
    }
  }

  if (!organisation && !usernames) return "";

  return (
    <div className="user-detail-screen organisation-detail">
      <div className="left">
        <div className="info-wrapper">
          <div className="info">
            <span>Kurum İsmi:</span>
            <Form.Control
              type="text"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Kurum Yetkilisi Mail:</span>
            <Form.Control
              type="text"
              value={organisationMail}
              onChange={(e) => setOrganisationMail(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Kurum Yetkilisi Telefon:</span>
            <Form.Control
              type="text"
              value={organisationPhone}
              onChange={(e) => setOrganisationPhone(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Kat Sayısı:</span>
            <Form.Control
              type="text"
              value={organisationFloors}
              onChange={(e) => setOrganisationFloors(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Oda Sayısı:</span>
            <Form.Control
              type="text"
              value={organisationRooms}
              onChange={(e) => setOrganisationRooms(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Fatura Bilgisi:</span>
            <Form.Control
              type="text"
              value={organisationBilling}
              onChange={(e) => setOrganisationBilling(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Kurum Aktiflik:</span>
            <Form.Select>
              <option
                value="Aktif"
                selected={organisationActive === "Aktif" && "true"}
              >
                Aktif
              </option>
              <option
                value="Pasif"
                selected={organisationActive === "Pasif" && "true"}
              >
                Pasif
              </option>
            </Form.Select>
          </div>
          <div className="info">
            <span>Kurum Yetkilisi Aktiflik:</span>
            <Form.Select>
              <option
                value="Aktif"
                selected={
                  organisationAuthorizedPersonActive === "Aktif" && "true"
                }
              >
                Aktif
              </option>
              <option
                value="Pasif"
                selected={
                  organisationAuthorizedPersonActive === "Pasif" && "true"
                }
              >
                Pasif
              </option>
            </Form.Select>
          </div>
          <div className="info person-add">
            <div className="add-section">
              <span>Kullanıcıları Yetkilendir:</span>
              <div className="list-box">
                <Form.Select onChange={handleSelectChange}>
                  <option value="" disabled>
                    Bir kullancı seçiniz
                  </option>
                  {usernames?.map((user, index) => (
                    <option value={user.userName} key={index}>
                      {user.userName}
                    </option>
                  ))}
                </Form.Select>
                <Button onClick={handleAddToList}>Ekle</Button>
              </div>
            </div>
            <div className="person-list">
              <p className="title">Kurumda Yetkili Kullanıcılar:</p>
              {Array.isArray(organisationAuthorizedPerson) &&
                organisationAuthorizedPerson.map((option, index) => (
                  <div key={index}>
                    <p>{option}</p>
                    <AiFillDelete onClick={() => handlePersonClick(index)} />
                  </div>
                ))}
            </div>
          </div>
          <Button variant="primary" onClick={updateOrganization}>
            Kaydet - Güncelle
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrganisationDetailScreen;
