import { useContext, useState, useEffect } from "react";
import { DasboardContext } from "../../context/dashboard-context";

import "bootstrap/dist/css/bootstrap.css";
import BedCard from "../../components/BedCard/BedCard";

import "./style.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Sidebar from "../../components/Sidebar/Sidebar";
import SameSuitCard from "../../components/SameSuitCard/SameSuitCard";

const MainScreen = () => {
  const { sidebarOpen, bedData, sameSuit, userInfo } =
    useContext(DasboardContext);
  const [filteredBed, setFilteredBed] = useState();

  const [status, setStatus] = useState("Cihazlara Bağlanılıyor...");
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (bedData.length > 0) {
      setFilteredBed(
        bedData?.filter(
          (item) => item.location !== "Depo" && item.location !== "Bakım"
        )
      );
      setFilteredBed(bedData?.filter((item) => item.suiteName === ""));
    }
  }, [bedData]);

  useEffect(() => {
    const timeouts = [];

    timeouts.push(
      setTimeout(() => {
        setStatus("Cihaz Durumu Sorgulanıyor...");
      }, 15000)
    );

    timeouts.push(
      setTimeout(() => {
        setStatus("Cihaz Durumu Güncellendi...");
      }, 30000)
    );

    timeouts.push(
      setTimeout(() => {
        setVisible();
      }, 45000)
    );

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  return (
    <>
      <Sidebar />
      <Breadcrumb />
      <div
        className={`device-list component-wrapper ${
          sidebarOpen ? "" : "close"
        }`}
      >
        {visible && (
          <div className="loader-bar">
            {" "}
            <div className="loaders"></div>
            <span>{status}</span>
          </div>
        )}
        <p>Toplam Bağlı Cihaz Sayısı: {bedData.length}</p>
        <p style={{ marginTop: "5px" }}>Şirket Adı: {userInfo?.company}</p>
        <div className="bed-card-wrapper">
          {filteredBed?.length > 0 &&
            filteredBed.map((bed, index) => (
              <BedCard bed={bed} key={bed.deviceID} />
            ))}
          {sameSuit?.length > 0 &&
            sameSuit.map((bed, index) => (
              <SameSuitCard bed={bed} key={index} />
            ))}
        </div>
        {/* <div className="accordion-menus">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Görevler</Accordion.Header>
              <Accordion.Body>
                <div className="user-missions">
                  <div className="missions">
                    <div className="mission-img">VK</div>
                    <div className="mission-info">
                      <div className="m-name">
                        <p>Veysel Kartalmış</p>
                        <span>5m ago</span>
                      </div>
                      <span>180/100 BP alarm</span>
                    </div>
                  </div>
                  <Link to={"/task-list"}>Tümünü Göster</Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Alarm Sayısı</Accordion.Header>
              <Accordion.Body>
                <div className="alarm-counter">
                  <p>Bugün Alarm Sayısı</p>
                  <h2>12</h2>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div> */}
      </div>
    </>
  );
};

export default MainScreen;
