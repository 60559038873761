import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import "./style.css";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const UserDetailScreen = () => {
  const [birthDay, setBirthDay] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [emergencyContacts, setEmergencyContacts] = useState("");
  const [anamnesis, setAnamnesis] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [devicesUsed, setDevicesUsed] = useState("");
  const [devicesIDs, setDevicesIDs] = useState("");
  const [userDetail, setUserDetail] = useState([]);
  const { id } = useParams();

  const [username, setUsername] = useState([]);
  const [devices, setDevices] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/users/${id}`);
      const users = await response.json();
      console.log(users);
      setUserDetail(users);
      setAddress(users.address);
      setAddressLink(users.addressLink);
      setPaymentStatus(users.paymentStatus);
      setGender(users.gender);
      setEmergencyContacts(users.emergencyContacts);
      setBirthDay(users.birthDay);
      setAnamnesis(users.anamnesis);
      setDevicesIDs(users.devicesIDs);
      setDevicesUsed(users.devicesUsed);
      setUsername(users.userName);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getDevices = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/devices`);
      const devices = await response.json();
      setDevices(devices);
      console.log(devices);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchData();
    getDevices();
    console.log(username);
  }, [id]);

  async function updateUser(e) {
    e.preventDefault();

    try {
      const response = await fetch("https://app.vivasmartcare.com/api/user", {
        method: "PUT",
        body: JSON.stringify({
          id,
          birthDay,
          gender,
          address,
          addressLink,
          emergencyContacts,
          anamnesis,
          paymentStatus,
          devicesUsed,
          devicesIDs,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        alert("Kişi Güncellendi");
        fetchData();
      } else {
        alert("Kişi güncellenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Güncelleme işlemi sırasında bir hata oluştu:", error);
    }
  }

  if (!userDetail) return "";

  return (
    <div className="user-detail-screen">
      <div className="left">
        <h3>{userDetail.nameSurname}</h3>
        <div className="info-wrapper">
          <div className="info">
            <span>Doğum Tarihi:</span>
            <Form.Control
              type="text"
              value={birthDay}
              onChange={(e) => setBirthDay(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Cinsiyet:</span>
            <Form.Select onChange={(e) => setGender(e.target.value)}>
              <option
                value="Erkek"
                selected={gender === "Erkek" ? true : false}
              >
                Erkek
              </option>
              <option
                value="Kadın"
                selected={gender === "Kadın" ? true : false}
              >
                Kadın
              </option>
            </Form.Select>
          </div>
          <div className="info">
            <span>Adres:</span>
            <Form.Control
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Adres Maps Linki:</span>
            <Form.Control
              type="text"
              value={userDetail.addressLink}
              onChange={(e) => setAddressLink(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Acil Durum Kontak Kişileri:</span>
            <Form.Select>
              <option value={emergencyContacts}>{emergencyContacts}</option>
            </Form.Select>
          </div>
          <div className="info">
            <span>Anamnez:</span>
            <Form.Control
              type="text"
              value={anamnesis}
              onChange={(e) => setAnamnesis(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Ödeme Durumu:</span>
            <Form.Control
              type="text"
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
            />
          </div>
          <div className="info">
            <span>Kullandığı Cihazlar:</span>
            <Form.Select
              value={devicesUsed}
              onChange={(e) => setDevicesUsed(e.target.value)}
            >
              <option
                value="Vayyar"
                selected={`${devicesUsed === "Vayyar" && true}`}
              >
                Vayyar
              </option>
              <option
                value="Minifinder"
                selected={`${devicesUsed === "Minifinder" && true}`}
              >
                Minifinder
              </option>
            </Form.Select>
          </div>
          <div className="info">
            <span>Cihaz ID:</span>
            <Form.Control
              type="text"
              value={devicesIDs}
              onChange={(e) => setDevicesIDs(e.target.value)}
            />
          </div>
        </div>
        <Button variant="primary" onClick={updateUser}>
          Kaydet - Güncelle
        </Button>
      </div>
      {devices.filter((device) => device.person === username).length > 0 && (
        <div className="right">
          <div className="info-box">
            <h6>Kullancıya Atanan Cihazlar:</h6>
            <div className="devices">
              {devices &&
                devices
                  .filter((device) => device.person === username)
                  .map((device) => (
                    <span key={device.id}>
                      {device.deviceID} -{" "}
                      <span className="bold">{device.roomName}</span>
                    </span>
                  ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetailScreen;
