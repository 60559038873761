import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "./style.css";
import { useEffect, useState } from "react";

const AlarmTable = ({ tableTD, tableData, bedData, userInfo }) => {
  const [filteredAlarm, setFilteredAlarm] = useState([]);
  const [suiteNames, setSuiteNames] = useState([]);

  const [hour, setHour] = useState("");
  const [suiteName, setSuiteName] = useState("");

  useEffect(() => {
    const data = tableData?.filter((data) =>
      bedData.some((bed) => bed.deviceID === data.deviceId)
    );
    setFilteredAlarm(data);

    const filteredData = tableData?.filter((data) =>
      bedData.some((bed) => bed.deviceID === data.deviceId)
    );

    const names = filteredData.map((data) => data.suiteName);
    setSuiteNames(names);
  }, [tableData, bedData]);

  useEffect(() => {
    const now = new Date();
    let data = tableData?.filter((data) =>
      bedData.some((bed) => bed.deviceID === data.deviceId)
    );

    if (hour.length !== 0 && hour === "last-8") {
      const eightHoursAgo = new Date(now.getTime() - 8 * 60 * 60 * 1000);
      data = data.filter((item) => new Date(item.createdAt) >= eightHoursAgo);
    } else if (hour.length !== 0 && hour === "last-week") {
      const lastWeek = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );
      data = data.filter((item) => new Date(item.createdAt) >= lastWeek);
    } else if (hour.length !== 0 && hour === "last-month") {
      const lastMonth = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      data = data.filter((item) => new Date(item.createdAt) >= lastMonth);
    }

    if (suiteName) {
      data = data.filter((item) => item.suiteName === suiteName);
    }

    setFilteredAlarm(data);
  }, [hour, suiteName, tableData, bedData]);

  function convertDate(timestampString) {
    const timestamp = Number(timestampString);

    if (isNaN(timestamp)) {
      return "Geçersiz zaman damgası";
    }

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return "Geçersiz zaman damgası";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    const formattedDate = date.toLocaleString("tr-TR", options);

    return formattedDate;
  }

  return (
    <>
      <div className="table-filters">
        <div className="dropdown-wrapper">
          <Form.Select onChange={(e) => setHour(e.target.value)}>
            <option>Tüm Zamanlar</option>
            <option value="last-8">Son 8 Saat</option>
            <option value="last-week">Geçen Hafta</option>
            <option value="last-month">Geçen Ay</option>
          </Form.Select>
        </div>
        {/* <div className="dropdown-wrapper">
          <Form.Select onChange={(e) => setSuiteName(e.target.value)}>
            <option>Süit Adı</option>
            {(() => {
              const uniqueOptions = new Set();
              return filteredAlarm
                ?.filter((data) => {
                  const device = bedData.find(
                    (device) => device.deviceID === data.deviceId
                  );
                  if (device && !uniqueOptions.has(device.suiteName)) {
                    uniqueOptions.add(device.suiteName);
                    return true;
                  }
                  return false;
                })
                .map((data, index) => {
                  const device = bedData.find(
                    (device) => device.deviceID === data.deviceId
                  );
                  return (
                    <option key={index} value={device?.suiteName}>
                      {device?.suiteName}
                    </option>
                  );
                });
            })()}
          </Form.Select>
        </div> */}
        <div className="dropdown-wrapper">
          <Form.Select>
            <option>Olay Türü</option>
            <option value="1">Düşme Olayları</option>
          </Form.Select>
        </div>
        <Button variant="primary">CVS Olarak Aktar</Button>
      </div>
      <Table striped bordered hover responsive className="alarm-table">
        <thead>
          <tr>
            {tableTD.map((tableRow, index) => (
              <th key={index}>{tableRow}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ fontSize: 12 }}>
          {filteredAlarm?.map((data, index) => (
            <tr key={index}>
              <td>{convertDate(data.endTimestamp)}</td>
              <td>
                {
                  bedData.find((device) => device.deviceID === data.deviceId)
                    ?.suiteName
                }
              </td>
              <td>
                <div className="table-icons">
                  <span>
                    {
                      bedData.find(
                        (device) => device.deviceID === data.deviceId
                      )?.roomName
                    }
                  </span>
                </div>
              </td>
              <td>
                <div className="event-type">
                  <div
                    className={`dot ${
                      data.eventType === "calling" ? "red" : ""
                    }`}
                  ></div>
                  <span style={{ fontSize: 12 }}>
                    {data.eventType === "calling" ? "Düşme Algılandı" : null}
                  </span>
                </div>
              </td>
              <td>{data.deviceId}</td>
              <td>{data.callCount}</td>
              <td
                className={
                  data.confirmation === "Teyit Edildi"
                    ? "green"
                    : data.confirmation === ""
                    ? "orange"
                    : "red"
                }
              >
                {data.confirmation}
              </td>
              <td>{data.description}</td>
              <td style={{ fontSize: "12px" }}>{data.alarmScenario}</td>
              <td>{data.person}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AlarmTable;
