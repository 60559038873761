import "./style.css";

import { HiArrowUp } from "react-icons/hi2";

const ScrollToTop = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="scroll-to-top" onClick={handleScrollToTop}>
      <HiArrowUp size={24} color="#fff" />
    </div>
  );
};

export default ScrollToTop;
