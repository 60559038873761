import { useState, useEffect, useContext } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { InputMask } from "@react-input/mask";

import "./style.css";
import { Link } from "react-router-dom";
import { DasboardContext } from "../../context/dashboard-context";

const DeviceTable = ({ tableTd, tableData }) => {
  const { usernames } = useContext(DasboardContext);

  const [addShow, setAddShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);

  const handleSuccess = () => setSuccessShow(false);
  const addHandleClose = () => setAddShow(false);
  const addhandleShow = () => setAddShow(true);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [deviceID, setDeviceID] = useState("");
  const [organization, setOrganization] = useState("");
  const [person, setPerson] = useState("");
  const [status, setStatus] = useState("");
  const [brand, setBrand] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [location, setLocation] = useState("");
  const [room, setRoom] = useState("");
  const [floor, setFloor] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomName, setRoomName] = useState("");
  const [smsPhone, setSmsPhone] = useState("");
  const [suiteName, setSuiteName] = useState("");
  const [deviceNote, setDeviceNote] = useState("");
  const [isDemo, setIsDemo] = useState();

  //For room count and floor count
  const [orgName, setOrgName] = useState();
  const [org, setOrg] = useState();
  const [floorCount, setFloorCount] = useState(0);
  const [roomCount, setRoomCount] = useState(0);

  //Filter
  const [filterText, setFilterText] = useState("");
  const [filterBrand, setFilterBrand] = useState("");
  const [filterOrganization, setFilterOrganization] = useState("");

  const filteredData = tableData.filter((row) => {
    return Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterBrand.toLowerCase()) &&
        value.toString().toLowerCase().includes(filterText.toLowerCase()) &&
        value
          .toString()
          .toLowerCase()
          .includes(filterOrganization.toLowerCase())
    );
  });

  async function addDevice(e) {
    e.preventDefault();

    setBtnDisabled(true);
    setShowSpinner(true);

    try {
      const response = await fetch("https://app.vivasmartcare.com/api/device-add", {
        method: "POST",
        body: JSON.stringify({
          deviceID,
          organization,
          person,
          status,
          brand,
          purchaseDate,
          installationDate,
          location,
          room,
          floor,
          roomName,
          deviceNote,
          roomType,
          smsPhone,
          suiteName,
          isDemo,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        setSuccessShow(true);
      } else {
        alert("Cihaz eklenirken bir hata oluştu");
      }
    } catch (error) {
      console.error("Ekleme işlemi sırasında bir hata oluştu:", error);
    } finally {
      setBtnDisabled(false);
      setShowSpinner(false);
      window.location.reload();
    }
  }

  const getOrganisations = async () => {
    try {
      const response = await fetch(`https://app.vivasmartcare.com/api/organisations`);
      const organisationName = await response.json();
      setOrgName(organisationName);
    } catch (error) {
      console.error("Veri getirilirken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    getOrganisations();
  }, []);

  useEffect(() => {
    const selectedOrg = orgName?.find(
      (org) => org.organisationName === organization
    );
    setOrg(selectedOrg);
    setFloorCount(parseInt(selectedOrg?.organisationFloors));
    setRoomCount(parseInt(selectedOrg?.organisationRooms));
  }, [organization]);

  return (
    <div className="device-table-wrapper">
      <div className="device-filter-inputs">
        <Form.Group>
          <Form.Control
            value={filterText}
            type="text"
            placeholder="Müşteri İsmine Göre Ara"
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Select
            value={filterBrand}
            onChange={(e) => setFilterBrand(e.target.value)}
          >
            <option defaultValue="">Markaya Göre Ara</option>
            {[...new Set(tableData.map((data) => data.brand))].map(
              (brand, index) => (
                <option value={brand} key={index}>
                  {brand}
                </option>
              )
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Select
            value={filterOrganization}
            onChange={(e) => setFilterOrganization(e.target.value)}
          >
            <option defaultValue="">Kurum İsmine Göre Ara</option>
            {orgName?.map((data, index) => (
              <option defaultValue={data.organisationName} key={index}>
                {data.organisationName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Button variant="primary" onClick={addhandleShow}>
            Cihaz Ekle
          </Button>
        </Form.Group>
      </div>
      <Table striped bordered hover responsive className="alarm-table">
        <thead>
          <tr>
            {tableTd.map((tableRow, index) => (
              <th key={index}>{tableRow}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <tr key={index}>
              <td className="table-id">{index + 1}</td>
              <td>{data.deviceID}</td>
              <td>{data.organization}</td>
              <td>{data.person}</td>
              <td>{data.status}</td>
              <td>{data.brand}</td>
              <td>{data.roomName}</td>
              <td>{data.suiteName}</td>
              <td>
                <Link to={`/devices/${data._id}`}>Düzenle</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={addShow} onHide={addHandleClose}>
        <Modal.Header>Cihaz Bilgisi Ekle</Modal.Header>
        <Modal.Body className="device-add-wrapper">
          <Form onSubmit={addDevice}>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Cihaz ID Giriniz"
                value={deviceID}
                required
                onChange={(e) => setDeviceID(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Select onChange={(e) => setOrganization(e.target.value)}>
                <option>Kurum Seç</option>
                {orgName?.map((org, index) => (
                  <option value={org.organisationName} key={index}>
                    {org.organisationName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Select
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                required
              >
                <option>Marka Seçiniz</option>
                <option value="Vayyar">Vayyar</option>
                <option value="Minifinder">Minifinder</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Select
                value={person}
                onChange={(e) => setPerson(e.target.value)}
                required
              >
                <option>Kullanıcı Seçiniz:</option>
                {usernames?.map((username, index) => (
                  <option value={username.userName} key={index}>
                    {username.userName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option>Cihaz Durumu</option>
                <option defaultValue="Aktif">Aktif</option>
                <option defaultValue="Pasif">Pasif</option>
                <option defaultValue="Teknik Arıza">Teknik Arıza</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <label>Satın Alma Tarihi Seçiniz:</label>
              <Form.Control
                type="date"
                value={purchaseDate}
                required
                onChange={(e) => setPurchaseDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label>Kurulum Tarihi Seçiniz:</label>
              <Form.Control
                type="date"
                value={installationDate}
                required
                onChange={(e) => setInstallationDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              >
                <option>Konum</option>
                <option defaultValue="Kurum">Kurum</option>
                <option defaultValue="Ev">Ev</option>
                <option defaultValue="Depo">Depo</option>
                <option defaultValue="Bakım">Bakım</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Select
                required
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
              >
                <option>Kat Seçiniz:</option>
                {floorCount &&
                  floorCount > 0 &&
                  [...Array(floorCount).keys()].map((floorCount, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}. Kat
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            {location === "Kurum" && (
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Süit Adı Giriniz:"
                  required
                  value={suiteName}
                  onChange={(e) => setSuiteName(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group>
              {location === "Kurum" || location === "Ev" ? (
                <Form.Select
                  value={roomType}
                  onChange={(e) => setRoomType(e.target.value)}
                  required
                >
                  <option>Oda Tipi Seçiniz:</option>
                  <option value="Banyo">Banyo</option>
                  <option value="Yatak Odası">Yatak Odası</option>
                  <option value="Aile Odası">Aile Odası</option>
                  <option value="Koridor">Koridor</option>
                  <option value="Mutfak">Mutfak</option>
                  <option value="Yemek Odası">Yemek Odası</option>
                  <option value="Oturma Odası">Oturma Odası</option>
                  <option value="Diğer">Diğer</option>
                </Form.Select>
              ) : (
                <Form.Select
                  required
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                >
                  <option>Oda Seçiniz:</option>
                  {roomCount &&
                    roomCount > 0 &&
                    [...Array(roomCount).keys()].map((roomCount, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}. Oda
                      </option>
                    ))}
                </Form.Select>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Odaya İsim Giriniz"
                required
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ display: "flex", flexDirection: "column" }}>
              <label>Bildirimler İçin Numara Giriniz:</label>
              <InputMask
                mask="+90__________"
                replacement={{ _: /\d/ }}
                value={smsPhone}
                showMask
                onChange={(e) => setSmsPhone(e.target.value)}
                className="form-control"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Cihaz ile İlgili Notları Giriniz"
                value={deviceNote}
                onChange={(e) => setDeviceNote(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Bu bir demo cihazıdır"
                checked={isDemo}
                onChange={(e) => setIsDemo(e.target.checked)}
              />
            </Form.Group>
            <div className="button-wrapper">
              <Button variant="secondary" onClick={addHandleClose}>
                İptal
              </Button>
              <Button
                variant="primary"
                type="submit"
                className={`${btnDisabled === true && "disabled"}`}
              >
                Ekle
              </Button>
              {showSpinner && <Spinner animation="border" variant="primary" />}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={successShow} onHide={handleSuccess}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Cihaz Başarıyla Eklendi.</Modal.Body>
      </Modal>
    </div>
  );
};

export default DeviceTable;
